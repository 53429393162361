import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import styled from "styled-components";

import Dropdown from "./Dropdown";

const DropdownWrapper = styled.div`
  .Select-value-label {
    color: ${props => props.theme.links} !important;
    font-weight: 600;
  }
  .dropdown > div {
    width: 132px;
  }
`;

const ActivityTypesDropdown = ({ onChange, intl }) => {
  const options = [
    { value: "all_type", label: intl.formatMessage({ id: "all_type" }) },
    {
      value: "branch",
      label: intl.formatMessage({ id: "branch" })
    },
    {
      value: "git",
      label: intl.formatMessage({ id: "git" })
    },
    {
      value: "merge",
      label: intl.formatMessage({ id: "merge" })
    },
    {
      value: "sync",
      label: intl.formatMessage({ id: "sync" })
    },
    {
      value: "system",
      label: intl.formatMessage({ id: "system" })
    }
  ];

  return (
    <DropdownWrapper>
      <Dropdown
        options={options}
        name="activity_types_filter"
        onChange={onChange}
        searchable={false}
        defaultValue={{
          value: "all_type",
          label: intl.formatMessage({ id: "all_type" })
        }}
        clearable={false}
        className="outlined"
      />
    </DropdownWrapper>
  );
};

ActivityTypesDropdown.propTypes = {
  intl: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func
};

export default injectIntl(ActivityTypesDropdown);
