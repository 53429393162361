const addHtmlLabel = require("./add-html-label"),
  addSVGLabel = require("./add-svg-label");

module.exports = addLabel;

function addLabel(root, node, location) {
  let label = node.label;
  let labelSvg = root.append("g");

  // Allow the label to be a string, a function that returns a DOM element, or
  // a DOM element itself.
  if (node.labelType === "svg") {
    addSVGLabel(labelSvg, node).attr("class", "tooltip");
  } else if (typeof label !== "string" || node.labelType === "html") {
    addHtmlLabel(labelSvg, node).attr("class", "tooltip");
  } else {
    addHtmlLabel(labelSvg, node).attr("class", "tooltip");
  }

  let labelBBox = labelSvg.node().getBBox();
  let y;
  switch (location) {
    case "top":
      y = -node.height / 2;
      break;
    case "bottom":
      y = node.height / 1.5;
      break;
    default:
      y = -labelBBox.height / 2;
  }
  labelSvg.attr(
    "transform",
    "translate(" + -labelBBox.width / 2 + "," + y + ")"
  );

  return labelSvg;
}
