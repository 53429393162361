import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { Link } from "react-router";
import ImmutablePropTypes from "react-immutable-proptypes";
import { Map } from "immutable";
import { LiveMessage } from "react-aria-live";

import {
  getEnvironmentGitCommand,
  getGitRemoteCommand,
  checkGoLive,
  getSubscription
} from "Libs/utils";
import {
  loadProjectActivities,
  loadMoreProjectActivities
} from "Reducers/activity";
import { MACHINE_NAME } from "Constants/constants";

import ActivityList from "Containers/ActivityList";
import InfoButtonsWrapper from "Components/InfoButtonsWrapper";
import ActionDropdown from "Components/ActionDropdown";
import CopyableArea from "Components/CopyableArea";
import ContentLayout from "Components/ContentLayout";
import ListGroup from "Components/ListGroup";
import ServiceDisplay from "Containers/ServicesDisplay";
import Heading2 from "Components/styleguide/Heading2";
import Screenshot from "Components/Screenshot";
import EnvironmentsTree from "Containers/EnvironmentsTree";
import ErrorBoundary from "Components/ErrorBoundary";
import ButtonLink from "Components/ButtonLink";

const LOAD_SUBSCRIPTION_STANDBY = "app/projects/load_subscription_standby";

const ColumnWrapper = styled.div`
  .services {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    h2 {
      margin: 0;
      padding: 32px 32px 38px;
    }
    > div {
      height: 100%;
      padding: 0 32px 32px;
    }
    svg {
      max-width: 100%;
    }
  }
  .project-info {
    overflow: hidden;
    h2 {
      margin-bottom: 0;
    }
    .live {
      display: inline-block;
      line-height: 32px;
      height: 32px;
      background: ${props => props.theme.highlightBg};
      padding: 0 24px;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 500;
      color: ${props => props.theme.links};
    }
    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;
    }
    .grid {
      color: #38485e;
      padding: 24px 32px;
      background: #fff;
      display: flex;
      flex-wrap: wrap;
      min-height: 154px;
      box-sizing: border-box;
    }
  }
  > section {
    overflow: hidden;
  }
  @media (min-width: 600px) {
    > section {
      overflow: visible;
    }
  }
  @media (min-width: 768px) {
    > aside {
      display: flex;
      width: 100%;
      section {
        &:first-child {
          margin-right: 32px;
        }
      }
    }
  }
  @media (min-width: 900px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    > aside {
      flex-grow: 1;
      margin-right: 32px;
      display: block;
      width: inherit;
      max-width: calc(84px * 4 + 32px * 3);
      .project-info {
        height: 442px;
      }
    }
    > div {
      flex-grow: 2;
      width: 63%;
      max-width: calc(100% - 464px);
      box-sizing: border-box;
    }
  }
  @media (min-width: 1800px) {
    > aside {
      max-width: calc(110px * 4 + 32px * 3);
    }
  }
  @media (min-width: 1800px) {
    > aside {
      max-width: calc(110px * 4 + 32px * 3);
    }
  }
`;

const BoxLayout = styled.div`
  &.environments,
  &.activity {
    padding: 32px;
    h2 {
      margin: 0 0 31px;
      line-height: 22px;
    }
  }
  &.activity {
    padding-bottom: 16px;
  }
  &.environments {
    min-height: 848px;
    box-sizing: border-box;
    h2 {
      margin-bottom: 36px;
    }
    &.no-code {
      min-height: 412px;
    }
  }
`;

const RowLayout = styled.div`
  display: grid;
  flex-direction: column;
`;

const ProjectScreenshot = styled.div`
  background: ${props => props.theme.screenshotBg};
  padding: 32px 32px 0;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  height: 100%;
  color: #fff;
  position: relative;
  &:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 30px;
    box-shadow: inset 0 -5px 10px rgba(0, 0, 0, 0.05);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  h2 {
    margin-bottom: 12px;
    color: #fff;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    .button {
      white-space: nowrap;
    }
  }
  .subtitle {
    margin-bottom: 20px;
    padding: 0;
    font-size: 14px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .subtitle-plan {
    text-transform: capitalize;
  }
  .screenshot {
    background: transparent;
    margin: 0;
    > div {
      padding: 0;
      position: relative;
      box-shadow: 0 2px 32px #0e0d34;
      margin: 0 auto;
      img {
        margin-bottom: -4px;
      }
      @media (min-width: 1800px) {
        max-width: 368px;
      }
    }
  }
`;

const ProjectInfo = styled.div`
  height: 64px;
  background: #fff;
  font-size: 15px;
  width: 50%;
  a {
    text-decoration: none;
    font-weight: 600;
    padding: 0 8px;
    margin: 0 -8px;
    line-height: 24px;
    font-size: 16px;
    color: ${props => props.theme.links};
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: none;
      border: 1px solid ${props => props.theme.buttonHover};
      border-radius: 4px;
      box-shadow: 0 0 2px ${props => props.theme.buttonHover};
      text-decoration: none;
      margin: -1px -9px 7px;
    }
    &:active {
      border: none;
      box-shadow: none;
      text-decoration: underline;
      margin: 0 -8px 8px;
    }
  }
  .label {
    color: #98a0ab;
    font-weight: 600;
    font-size: 14px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .value {
    line-height: 18px;
    min-height: 18px;
    color: #38485e;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .region,
  .members {
    color: #242428;
  }
  div.plan:first-letter {
    text-transform: uppercase;
  }
  @media (min-width: 550px) {
    border-bottom: none;
  }
`;

const BlankSetup = styled.div`
  box-sizing: border-box;
  padding: 32px;
  margin-bottom: 32px;
  background: ${props => props.theme.highlightBg};
  border-radius: 4px;
  background-image: repeating-linear-gradient(
      to right,
      ${props => props.theme.brandColor} 0%,
      ${props => props.theme.brandColor} 50%,
      transparent 50%,
      transparent 65%,
      ${props => props.theme.brandColor} 65%,
      ${props => props.theme.brandColor} 85%,
      transparent 85%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to right,
      ${props => props.theme.brandColor} 0%,
      ${props => props.theme.brandColor} 50%,
      transparent 50%,
      transparent 65%,
      ${props => props.theme.brandColor} 65%,
      ${props => props.theme.brandColor} 85%,
      transparent 85%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to bottom,
      ${props => props.theme.brandColor} 0%,
      ${props => props.theme.brandColor} 50%,
      transparent 50%,
      transparent 65%,
      ${props => props.theme.brandColor} 65%,
      ${props => props.theme.brandColor} 85%,
      transparent 85%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to bottom,
      ${props => props.theme.brandColor} 0%,
      ${props => props.theme.brandColor} 50%,
      transparent 50%,
      transparent 65%,
      ${props => props.theme.brandColor} 65%,
      ${props => props.theme.brandColor} 85%,
      transparent 85%,
      transparent 100%
    );
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 24px 1px, 24px 1px, 1px 24px, 1px 24px;
  a {
    color: ${props => props.theme.links};
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
    &.button {
      color: #fff;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
  p,
  ul {
    color: #302f45;
    font-size: 14px;
  }
  p {
    line-height: 19px;
    margin: 0 0 24px;
  }
  ul {
    padding: 0;
    margin-bottom: 32px;
    li {
      list-style: none;
      padding: 0;
      line-height: 24px;
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .project-get-remote > button {
    width: 134px;
  }
  .info-buttons {
    button {
      background: #fff;
    }
    .info-button + .info-button {
      margin-left: 12px;
    }
  }
  h3 {
    color: #1a192b;
    font-weight: 600;
    font-size: 16px;
    margin: 0 0 8px;
    line-height: 24px;
  }
`;

const DownloadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 15px;
  a {
    color: ${props => props.theme.links};
    display: flex;
    align-items: center;
    text-decoration: none;
    svg {
      margin-right: 8px;
    }
    .download-icon {
      fill: ${props => props.theme.links};
    }
  }
`;

const SetupHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const SetupItem = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Number = styled.div`
  height: 24px;
  width: 24px;
  background: rgba(71, 134, 255, 0.24);
  color: ${props => props.theme.links};
  line-height: 24px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
`;

const Content = styled.div`
  margin-left: 16px;
  width: 100%;
`;

class ProjectOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      main_environment: "",
      hasError: false,
      subscriptionStatus: LOAD_SUBSCRIPTION_STANDBY,
      subscriptionData: {}
    };
    this.loadMoreProjectActivities = this.loadMoreProjectActivities.bind(this);
    this.loadProjectActivitiesOfType = this.loadProjectActivitiesOfType.bind(
      this
    );
    this.getEnvironmentIdForServices = this.getEnvironmentIdForServices.bind(
      this
    );
    this.getMainEnvironment = this.getMainEnvironment.bind(this);
  }

  async componentDidUpdate() {
    const { project } = this.props;
    const { subscriptionStatus } = this.state;
    if (
      subscriptionStatus === LOAD_SUBSCRIPTION_STANDBY &&
      project.getSubscriptionId
    ) {
      getSubscription(project, this);
    }
  }

  getMainEnvironment(environments) {
    let mainEnvironment = "";
    if (environments) {
      mainEnvironment = environments.find(environment => environment.is_main);
    }
    return mainEnvironment;
  }

  getEnvironmentIdForServices() {
    const { environments } = this.props;
    if (environments.size) {
      const environmentIdForServices = this.props.environments.get("master")
        ? "master"
        : environments.toArray()[0].id;
      return environmentIdForServices;
    }
    return "master";
  }

  loadProjectActivitiesOfType({ value }) {
    this.props.loadProjectActivities(
      this.props.projectId,
      this.props.organizationId,
      value
    );
  }

  loadMoreProjectActivities() {
    this.props.loadMoreProjectActivities(this.props.project.id);
  }

  render() {
    const {
      intl,
      project,
      organizationId,
      orgByProjectId,
      projectId,
      setRedirect
    } = this.props;

    const { subscriptionData } = this.state;
    const environments = this.props.environments.valueSeq();
    const mainEnvironment = this.getMainEnvironment(environments);
    const noCode =
      mainEnvironment && mainEnvironment.hasPermission("#initialize");
    const region = this.props.regionByUrl.get(project.region);
    let cliCommand, platformCliCommand, gitRemoteComand;
    if (project) {
      gitRemoteComand = getGitRemoteCommand(project, MACHINE_NAME);
      if (mainEnvironment) {
        cliCommand = getEnvironmentGitCommand(
          project,
          mainEnvironment,
          MACHINE_NAME,
          "git-project"
        );
        platformCliCommand = getEnvironmentGitCommand(
          project,
          mainEnvironment,
          MACHINE_NAME,
          "cli-project"
        );
      }
    }
    if (!orgByProjectId) return null;
    if (!this.props.redirected && organizationId !== orgByProjectId) {
      setRedirect(`/${orgByProjectId}/${projectId}`);
      return null;
    }

    const goLive = checkGoLive(project);

    return (
      <ContentLayout>
        <ErrorBoundary>
          <LiveMessage
            message={`${project && project.title} overview`}
            aria-live="polite"
          />
          <ColumnWrapper>
            <aside className="col-4">
              <ListGroup
                className="project-info"
                aria-labelledby="project-heading"
              >
                <BoxLayout>
                  <ProjectScreenshot id="project-screenshot">
                    <Heading2 id="project-heading">
                      {project && project.title}
                      {/* @todo: move to separate component since this is in EnvironmentOverview.js also?  */}
                      {project.subscription &&
                      project.subscription.plan &&
                      goLive === "live" ? (
                        <span className="live">Live</span>
                      ) : goLive === "development" ? (
                        <ButtonLink
                          to={
                            subscriptionData &&
                            subscriptionData._links &&
                            subscriptionData._links.plan_uri.href
                          }
                          className="go-live"
                          text="Go live"
                          external={true}
                          blank={false}
                        />
                      ) : goLive === "no-permission" ? (
                        ""
                      ) : (
                        <ButtonLink
                          to={
                            goLive === "development"
                              ? `/${this.props.organizationId}/${
                                  this.props.projectId
                                }/settings`
                              : `/${this.props.organizationId}/${
                                  this.props.projectId
                                }/settings/domains`
                          }
                          className="go-live"
                          text="Go live"
                        />
                      )}
                    </Heading2>
                    <div className="subtitle">
                      Plan:{" "}
                      <span className="subtitle-plan">
                        {subscriptionData.plan && subscriptionData.plan}
                      </span>
                    </div>
                    <Screenshot
                      imagePath={`/${
                        this.props.projectId
                      }/master/screenshot.png`}
                    />
                  </ProjectScreenshot>
                  {project && (
                    <div className="grid">
                      <ProjectInfo>
                        <div className="value environments">
                          {project.subscription
                            ? project.subscription.environments + 1
                            : ""}
                        </div>
                        <div className="label">No. of Environments</div>
                      </ProjectInfo>
                      <ProjectInfo>
                        <div className="value region">
                          {region ? region : ""}
                        </div>
                        <div className="label">Region</div>
                      </ProjectInfo>
                      <ProjectInfo>
                        <div className="value members">
                          {project.subscription &&
                            project.subscription.user_licenses}
                        </div>
                        <div className="label">
                          {intl.formatMessage({ id: "members" })}
                        </div>
                      </ProjectInfo>

                      <ProjectInfo>
                        <div className="value members">
                          {project.subscription &&
                            `${project.subscription.storage / 1024} GB`}
                        </div>
                        <div className="label">
                          {intl.formatMessage({ id: "storage" })}
                        </div>
                      </ProjectInfo>
                    </div>
                  )}
                </BoxLayout>
              </ListGroup>
              <ListGroup aria-labelledby="services-heading">
                <BoxLayout className="services">
                  <Heading2 id="services-heading">
                    {intl.formatMessage({ id: "services" })}
                  </Heading2>
                  <RowLayout>
                    <ServiceDisplay
                      height="250px"
                      minHeight="250px"
                      environmentId={this.getEnvironmentIdForServices()}
                      display="project"
                      {...this.props}
                    />
                  </RowLayout>
                </BoxLayout>
              </ListGroup>
            </aside>
            <div>
              {noCode && (
                <BlankSetup>
                  <SetupHeader>
                    <Heading2 id="blank-project">
                      Set up your blank project
                    </Heading2>
                    <InfoButtonsWrapper className="info-buttons">
                      <ActionDropdown
                        id="project-git"
                        label="Git"
                        ariaLabel="Git"
                      >
                        <CopyableArea
                          id="project-git-cmd"
                          content={cliCommand}
                          singleLine={true}
                        >
                          {cliCommand}
                        </CopyableArea>
                      </ActionDropdown>
                      <ActionDropdown
                        id="project-cli"
                        label="CLI"
                        ariaLabel="CLI"
                      >
                        <CopyableArea
                          id="project-cli-cmd"
                          content={platformCliCommand}
                          singleLine={true}
                        >
                          {platformCliCommand}
                        </CopyableArea>
                        <DownloadWrapper>
                          <Link
                            to="https://github.com/platformsh/platformsh-cli"
                            tabIndex="0"
                            aria-label="Insall CLI"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Install CLI
                          </Link>
                        </DownloadWrapper>
                      </ActionDropdown>
                    </InfoButtonsWrapper>
                  </SetupHeader>
                  <SetupItem>
                    <Number>1</Number>
                    <Content>
                      <h3>Platform.sh Git remote</h3>
                      <p>
                        If you have a local Git repository already available,
                        add your Platform.sh repository as a new remote:
                      </p>
                      <CopyableArea
                        id="project-git-remote-cmd"
                        content={gitRemoteComand}
                        singleLine={true}
                      >
                        {gitRemoteComand}
                      </CopyableArea>
                    </Content>
                  </SetupItem>
                  <SetupItem>
                    <Number>2</Number>
                    <Content>
                      <h3>Push to master</h3>
                      <p>
                        To run services on Platform.sh, add the necessary
                        configuration files, see our{" "}
                        <Link
                          to="https://docs.platform.sh/configuration/yaml.html"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          documentation
                        </Link>.
                      </p>
                      <p>
                        <strong>Required files:</strong>{" "}
                        <code>.platform.app.yaml</code>{" "}
                        <code>.platform/services.yaml</code>{" "}
                        <code>.platform/routes.yaml</code>
                      </p>
                      <CopyableArea
                        id="project-git-remote-cmd"
                        content={`git add .platform.app.yaml .platform/ && git commit -m "Platform.sh configuration files" && git push -u platform master`}
                        threeLine={true}
                      >
                        git add .platform.app.yaml .platform/<br />
                        git commit -m "Platform.sh configuration files" <br />
                        git push -u platform master
                      </CopyableArea>
                    </Content>
                  </SetupItem>
                  <hr />
                  <h3>From an external Git repository</h3>
                  <p>
                    Link your Platform.sh repository with external repositories
                    such as{" "}
                    <Link
                      to="https://github.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Github
                    </Link>,{" "}
                    <Link
                      to="https://about.gitlab.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Gitlab
                    </Link>, or{" "}
                    <Link
                      to="https://bitbucket.org/product"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Bitbucket
                    </Link>. Then run the CLI command in the dropdown to run
                    your Platform project.
                  </p>
                  <ButtonLink
                    to="https://docs.platform.sh/administration/integrations.html"
                    text="Learn more"
                    external={true}
                  />
                </BlankSetup>
              )}
              <ListGroup aria-labelledby="environments-heading">
                <BoxLayout
                  className={`environments${noCode ? " no-code" : ""}`}
                >
                  <Heading2 id="environments-heading">
                    {intl.formatMessage({ id: "environments" })}
                  </Heading2>
                  <div>
                    <EnvironmentsTree
                      projectId={this.props.projectId}
                      organizationId={this.props.organizationId}
                    />
                  </div>
                </BoxLayout>
              </ListGroup>
            </div>
          </ColumnWrapper>
          <ListGroup aria-labelledby="project-activity-heading">
            <BoxLayout className="activity">
              <Heading2 id="environments-heading">
                {intl.formatMessage({ id: "activity" })}
              </Heading2>
              <ActivityList
                organizationId={this.props.organizationId}
                projectId={this.props.projectId}
                loadMore={this.loadMoreProjectActivities}
                loadActivitiesOfType={this.loadProjectActivitiesOfType}
                activities={this.props.activity}
                isLoading={this.props.activityIsLoading}
                hasMore={this.props.hasMore}
                canEditProject={
                  this.props.project &&
                  typeof this.props.project.hasPermission === "function" &&
                  this.props.project.hasPermission("#edit")
                }
                activityContext="project"
              />
            </BoxLayout>
          </ListGroup>
        </ErrorBoundary>
      </ContentLayout>
    );
  }
}

ProjectOverview.propTypes = {
  objects: PropTypes.array,
  projectId: PropTypes.string,
  environmentId: PropTypes.string,
  organizationId: PropTypes.string,
  orgByProjectId: PropTypes.string,
  intl: PropTypes.object,
  project: PropTypes.object,
  environments: ImmutablePropTypes.map,
  loadMoreProjectActivities: PropTypes.func,
  loadProjectActivities: PropTypes.func,
  setRedirect: PropTypes.func,
  redirected: PropTypes.bool,
  regionByUrl: ImmutablePropTypes.map,
  activity: ImmutablePropTypes.map,
  activityIsLoading: PropTypes.bool,
  hasMore: PropTypes.bool
};

const mapStateToProps = (state, props) => {
  const environment = state.environment || new Map();

  return {
    environments: environment.getIn(
      ["data", props.organizationId, props.projectId],
      new Map()
    ),
    project: state.project.getIn(
      ["data", props.organizationId, props.projectId],
      {}
    ),
    orgByProjectId: state.project.getIn(["orgByProjectId", props.projectId]),
    regionByUrl: state.region.get("byUrl", new Map()),
    activity: state.activity.getIn(
      ["byProject", "data", props.organizationId, props.projectId],
      new Map()
    ),
    hasMore: state.activity.getIn(["byProject", "hasMore"], false),
    activityIsLoading: state.activity.getIn(["byProject", "loading"])
  };
};

const mapDispatchToProps = dispatch => ({
  getMe: () =>
    import("Reducers/app").then(reducer => dispatch(reducer.getme())),
  loadProjectActivities: (projectId, organizationId, type) => {
    dispatch(loadProjectActivities(projectId, organizationId, type));
  },
  loadMoreProjectActivities: projectId =>
    dispatch(loadMoreProjectActivities(projectId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ProjectOverview));
