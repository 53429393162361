import styled from "styled-components";

export default styled.div`
  &::after {
    position: absolute;
    content: "";
    background-color: #c9d0e4;
    width: 1px;
    left: ${props => props.left};
    top: ${props => props.top};
    height: ${props => props.size};
  }
`;
