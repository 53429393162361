import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { request } from "Libs/platform";
import ScreenshotPlaceholder from "Images/placeholders/screenshot.png";
import ScreenshotDefault from "Images/placeholders/screenshot-default.svg";
import { SCREENSHOT_API } from "Constants/constants";

const ScreenshotWrapper = styled.div`
  background: #1a192b;
  width: 100%;
  position: relative;
  border-radius: 2px 2px 0 0
  &:after {
    content: "";
    display: inline-block;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 1px);
    border-bottom: 1px solid #f0f2f5;
    position: absolute;
  }
  &.no-screenshot .with-image {
    border-radius: 2px 2px 0 0;
    overflow: hidden;
  }
`;

const ImageWrapper = styled.div`
  margin: 0;
  box-sizing: border-box;
  height: 218px;
  text-align: center;
  background: #fff;
  display: flex;
  align-items: flex-start;
  img {
    width: 100%;
    height: auto;
    background: #fff;
  }
  svg {
    width: 40%;
    height: auto
    margin: 20px auto;
  }
  &.without-image {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
  }
`;

class Screenshot extends React.Component {
  constructor() {
    super();

    this.state = {};
  }

  componentDidMount() {
    const image = new Image();

    this.setState({
      loading: true
    });

    request(`${SCREENSHOT_API}${this.props.imagePath}`, "GET")
      .then(response => {
        response.arrayBuffer().then(buffer => {
          const base64Flag = "data:image/jpeg;base64,";
          const imageStr = arrayBufferToBase64(buffer);

          image.src = base64Flag + imageStr;
          const screenshotPath =
            imageStr.length > 0 ? base64Flag + imageStr : "";
          this.setState({
            loading: false,
            screenshotPath
          });
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: err
        });
      });

    const arrayBufferToBase64 = buffer => {
      let binary = "";
      const bytes = [].slice.call(new Uint8Array(buffer));

      bytes.forEach(b => (binary += String.fromCharCode(b)));

      return window.btoa(binary);
    };
  }

  render() {
    const { imagePath } = this.props;
    const { screenshotPath } = this.state;

    return (
      <ScreenshotWrapper
        className={`screenshot${screenshotPath ? "" : " no-screenshot"}`}
      >
        <ImageWrapper
          className={`${imagePath ? "with-image" : "without-image"}`}
        >
          <img
            src={
              screenshotPath
                ? screenshotPath
                : this.state.loading
                  ? ScreenshotPlaceholder
                  : ScreenshotDefault
            }
            alt="screenshot"
          />
        </ImageWrapper>
      </ScreenshotWrapper>
    );
  }
}

Screenshot.propTypes = {
  imagePath: PropTypes.string
};

export default Screenshot;
