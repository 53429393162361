import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CustomScroll from "react-custom-scroll";
import { LiveMessage } from "react-aria-live";

import MenuLinkItem from "./MenuLinkItem";
import SearchInput from "Components/SearchInput";

const MenuWrapper = styled.nav`
  .rcs-custom-scroll {
    .rcs-custom-scrollbar {
      opacity: 1;
    }
    .rcs-inner-container {
      padding: 8px 0;
      box-sizing: border-box;
    }
    .rcs-inner-handle {
      background: #4a495e;
      width: 4px;
      border-radius: 4px;
      opacity: 1;
    }
  }
  .no-scroll {
    padding: 8px 0;
  }
  ol {
    margin: 0;
    padding: 0;
  }
`;

const Environments = styled.div`
  width: 100%;
  clear: both;
  border-top: 1px solid #eee;
`;

const Empty = styled.div`
  color: #1a192b;
  line-height: 32px;
  padding: 0 16px;
  font-size: 14px;
`;

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleFilter: ""
    };

    this.onSearchChange = this.onSearchChange.bind(this);
    this.environmentFilter = this.environmentFilter.bind(this);
  }

  onSearchChange(event) {
    this.setState({
      titleFilter: event.target.value
    });
  }

  environmentFilter(environment) {
    if (environment.status === "inactive") {
      return false;
    }

    const title = environment.title;

    if (!title) {
      return true;
    }

    return title.toUpperCase().includes(this.state.titleFilter.toUpperCase());
  }

  render() {
    const { list, id, tree } = this.props;

    if (!list || !list.length) {
      return false;
    }

    let listCount = list.length;
    let environments = tree;
    if (this.state.titleFilter !== "") {
      environments = list.filter(this.environmentFilter).map(l => {
        return { ...l, children: [] };
      });
    }
    return (
      <MenuWrapper role="navigation">
        {this.state.titleFilter && (
          <LiveMessage
            message={`environment list results filtered by ${
              this.state.titleFilter
            }`}
            aria-live="polite"
          />
        )}
        <SearchInput
          inputColor="#404041"
          iconSize={14}
          placeholder="Search environments"
          onChange={this.onSearchChange}
          value={this.state.titleFilter}
          className="search"
          id="environment-search"
        />
        <Environments
          className={`environments-links`}
          role="menu"
          aaria-label="environments"
        >
          {listCount > 10 ? (
            <CustomScroll heightRelativeToParent="332px">
              <ol>
                {environments
                  .sort((a, b) => {
                    if (a.title.toLowerCase() < b.title.toLowerCase())
                      return -1;
                    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                    return 0;
                  })
                  .map(node => {
                    return (
                      <MenuLinkItem
                        id={id}
                        node={node}
                        organizationId={this.props.organizationId}
                        currentEnvironment={this.props.currentEnvironment}
                        key={node.environmentId}
                        searchValue={this.state.titleFilter}
                        filter={this.environmentFilter}
                      />
                    );
                  })}
              </ol>
            </CustomScroll>
          ) : listCount === 0 ? (
            <Empty>No results.</Empty>
          ) : (
            <div className="no-scroll">
              <ol>
                {environments
                  .sort((a, b) => {
                    if (a.title.toLowerCase() < b.title.toLowerCase())
                      return -1;
                    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                    return 0;
                  })
                  .map(node => {
                    return (
                      <MenuLinkItem
                        id={id}
                        node={node}
                        organizationId={this.props.organizationId}
                        currentEnvironment={this.props.currentEnvironment}
                        key={node.environmentId}
                        searchValue={this.state.titleFilter}
                        filter={this.environmentFilter}
                      />
                    );
                  })}
              </ol>
            </div>
          )}
        </Environments>
      </MenuWrapper>
    );
  }
}

Menu.propTypes = {
  isRoot: PropTypes.bool,
  list: PropTypes.array,
  tree: PropTypes.array,
  organizationId: PropTypes.string,
  currentEnvironment: PropTypes.string,
  id: PropTypes.string
};

export default Menu;
