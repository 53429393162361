const addLabel = require("../label/add-label"),
  images = require("./images"),
  util = require("./util"),
  d3 = require("d3");

module.exports = createNodes;

function createNodes(selection, g) {
  let simpleNodes = g.nodes().filter(function(v) {
    return !util.isSubgraph(g, v);
  });
  let svgNodes = selection
    .selectAll("g.node")
    .data(simpleNodes, function(v) {
      return v;
    })
    .classed("update", true);

  svgNodes.exit().remove();

  svgNodes
    .enter()
    .append("g")
    .attr("class", "node")
    .style("opacity", 0);

  svgNodes = selection.selectAll("g.node");

  svgNodes.each(function(v) {
    let node = g.node(v),
      thisGroup = d3.select(this);
    util.applyClass(
      thisGroup,
      node["class"],
      (thisGroup.classed("update") ? "update " : "") + "node"
    );

    node.elem = this;

    const { width, height } = node;
    const bbox = { width, height };

    if (node.id) {
      thisGroup.attr("id", node.id);
    }
    if (node.labelId) {
      thisGroup.attr("id", node.labelId);
    }
    thisGroup.select(".label-container").remove();
    let w = bbox.width / Math.SQRT2;
    let rectNode = thisGroup
      .insert("rect", ":first-child")
      .classed("label-container", true)
      .attr("transform", "rotate(-45)")
      .attr("rx", 8)
      .attr("ry", 8)
      .attr("x", -w / 2)
      .attr("y", -w / 2)
      .attr("width", w)
      .attr("height", w)
      .style("filter", "url(#shadow)");

    thisGroup
      .on("mouseover", function() {
        rectNode.style("filter", "url(#shadow-hover)");
      })
      .on("mouseout", function() {
        rectNode.style("filter", "url(#shadow)");
      });
    thisGroup.select(".icon").remove();
    thisGroup
      .insert("g")
      .classed("icon", true)
      .html(
        '<image x="-8" y="-8" width="16" height="16" xlink:href="' +
          images[node.icon] +
          '"></image>'
      );

    addLabel(thisGroup, node, "bottom");
  });

  let exitSelection;

  if (svgNodes.exit) {
    exitSelection = svgNodes.exit();
  } else {
    exitSelection = svgNodes.selectAll(null); // empty selection
  }

  util
    .applyTransition(exitSelection, g)
    .style("opacity", 0)
    .style("filter", "url(#blur)")
    .remove();

  return svgNodes;
}
