import styled from "styled-components";

export default styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 92px;
  position: absolute;
  right: 0;
  line-height: 32px;
  .link {
    cursor: pointer;
    display: inline-block;
  }
  .moment {
    box-sizing: border-box;
    padding: 0;
    font-weight: 400;
    color: #5f5e70;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  @media (min-width: 900px) {
    font-size: 16px;
  }
`;
