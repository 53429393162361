import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import CustomScroll from "react-custom-scroll";
import ReactTooltip from "react-tooltip";

import LockIcon from "Icons/LockIcon";
import VisuallyHidden from "Components/VisuallyHidden";

const RouteLink = styled.li`
  list-style: none;
  .route-link {
    white-space: nowrap;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #404041;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    .path {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.secure {
      display: flex;
      align-items: center;
    }
    .secure-icon {
      display: flex;
      align-items: center;
      height: 24px;
      width: 24px;
      min-width: 24px;
      margin-left: 10px;
      svg {
        width: 100%;
        height: auto;
      }
    }
    &:hover {
      text-decoration: none;
      color: ${props => props.theme.links};
    }
    &:focus,
    &:active {
      margin-left: -1px;
    }
    .chevron > svg {
      transform: rotate(-90deg);
    }
  }
`;

const RouteList = styled.ul`
  margin: 0;
  padding: 0;
`;

class EnvironmentRoutes extends React.Component {
  securePath(path) {
    return path.startsWith("https");
  }

  sortRoutes(a, b) {
    return a.toUpperCase() < b.toUpperCase();
  }

  render() {
    const { routes, environmentId } = this.props;

    return typeof routes !== "undefined" && routes.length > 8 ? (
      <CustomScroll heightRelativeToParent="248px">
        <RouteList>
          {routes.sort((a, b) => this.sortRoutes(a, b)).map(
            (route, index) =>
              route.includes("*") ? (
                ""
              ) : (
                <RouteLink key={route}>
                  <a
                    id={`${environmentId}-route-link-${index}`}
                    className={`route-link${
                      this.securePath(route) ? " secure" : ""
                    }`}
                    href={route}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="path">{route}</span>
                    <VisuallyHidden>Opens a new window.</VisuallyHidden>
                    {this.securePath(route) && (
                      <span
                        className="secure-icon"
                        data-tip
                        data-for={`${environmentId}-route-link-${index}-tooltip`}
                      >
                        <LockIcon
                          iconId={`${environmentId}-route-link-${index}`}
                        />
                        <ReactTooltip
                          id={`${environmentId}-route-link-${index}-tooltip`}
                          place="bottom"
                          type="dark"
                          effect="solid"
                        >
                          SSL encrypted
                        </ReactTooltip>
                      </span>
                    )}
                  </a>
                </RouteLink>
              )
          )}
        </RouteList>
      </CustomScroll>
    ) : (
      <div className="no-scroll">
        <RouteList>
          {routes &&
            routes.sort((a, b) => this.sortRoutes(a, b)).map(
              (route, index) =>
                route.includes("*") ? (
                  ""
                ) : (
                  <RouteLink key={route}>
                    <a
                      id={`${environmentId}-route-link-${index}`}
                      className={`route-link${
                        this.securePath(route) ? " secure" : ""
                      }`}
                      href={route}
                      target="_blank"
                      rel="noopener noreferrer"
                      role="menuitem"
                      aria-label={route}
                    >
                      <span className="path">{route}</span>
                      <VisuallyHidden>Opens a new window.</VisuallyHidden>
                      {this.securePath(route) && (
                        <span
                          className="secure-icon"
                          data-tip
                          data-for={`${environmentId}-route-link-${index}-tooltip`}
                        >
                          <LockIcon
                            iconId={`${environmentId}-route-link-${index}`}
                          />
                          <ReactTooltip
                            id={`${environmentId}-route-link-${index}-tooltip`}
                            place="bottom"
                            type="dark"
                            effect="solid"
                          >
                            SSL encrypted
                          </ReactTooltip>
                        </span>
                      )}
                    </a>
                  </RouteLink>
                )
            )}
        </RouteList>
      </div>
    );
  }
}

EnvironmentRoutes.propTypes = {
  environmentId: PropTypes.string,
  routes: PropTypes.array
};

const mapStateToProps = (state, props) => {
  const environment = state.environment.getIn([
    "data",
    props.organizationId,
    props.projectId,
    props.environmentId
  ]);

  let routes;

  if (environment && environment.getRouteUrls) {
    routes = environment.getRouteUrls();
  }

  return {
    routes
  };
};

export default connect(mapStateToProps)(EnvironmentRoutes);
