import React from "react";
import styled from "styled-components";

const PendingIconWrapper = styled.span`
  height: 32px;
  width: 32px;
  display: inline-block;
  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

const InProgressStateIcon = () => {
  return (
    <PendingIconWrapper>
      <svg
        className="icon-loading"
        width="40px"
        height="40px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
        preserveAspectRatio="xMidYMid"
        style={{ background: "none" }}
        alt="Loading"
      >
        <circle
          cx="20"
          cy="20"
          ng-attr-r="{{config.radius}}"
          ng-attr-stroke="{{config.base}}"
          ng-attr-stroke-width="{{config.width}}"
          fill="none"
          r="10"
          stroke="#e8f0ff"
          strokeWidth="4"
        />
        <circle
          cx="20"
          cy="20"
          ng-attr-r="{{config.radius}}"
          ng-attr-stroke="{{config.stroke}}"
          ng-attr-stroke-width="{{config.innerWidth}}"
          ng-attr-stroke-linecap="{{config.linecap}}"
          fill="none"
          r="10"
          stroke="#4786ff"
          strokeWidth="4"
          strokeLinecap="round"
          transform="rotate(177.406 20 20)"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values="0 20 20;180 20 20;720 20 20"
            keyTimes="0;0.5;1"
            dur="1.3s"
            begin="0s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-dasharray"
            calcMode="linear"
            values="6.283185307179586 56.548667764616276;50.26548245743669 12.566370614359172;6.283185307179586 56.548667764616276"
            keyTimes="0;0.5;1"
            dur="1.3"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </PendingIconWrapper>
  );
};

export default InProgressStateIcon;
