import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";

import ChevronIcon from "Components/icons/ChevronIcon";
import ListGroup from "Components/ListGroup";
import ActionButtonWrapper from "Components/ActionButtonWrapper";
import ActionButtonToggle from "Components/ActionButtonToggle";
import ActionWindow from "Components/ActionWindow";
import Label from "Components/fields/Label";
import CloseBtn from "Components/CloseBtn";

class ActionDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside(e) {
    if (
      !ReactDOM.findDOMNode(this).contains(e.target) &&
      e.target.className !== "copy"
    ) {
      this.setState({
        isOpen: false
      });
    }
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const {
      id,
      label,
      extraLabel,
      children,
      className,
      inline,
      withArrow = true,
      icon,
      ariaLabel,
      status,
      iconAfter,
      withClose
    } = this.props;

    return (
      <ActionButtonWrapper
        id={id}
        className={`info-button${inline ? " inline-dropdown" : ""}${
          this.state.isOpen ? " open-wrapper" : ""
        }${className ? " " + className : ""}`}
        onClick={e => e.stopPropagation()}
        onKeyUp={e => {
          if (e.keyCode === 27 && this.state.isOpen) {
            this.toggleOpen();
          }
          if (e.keyCode === 13 || e.keyCode === 40) {
            this.toggleOpen();
          }
        }}
      >
        {this.state.isOpen && (
          <LiveMessage
            message={`${label} popup menu has been opened.`}
            aria-live="polite"
          />
        )}
        {!this.state.isOpen && (
          <LiveMessage
            message={`${label} popup menu has been closed.`}
            aria-live="polite"
          />
        )}
        <ActionButtonToggle
          type="button"
          aria-label={ariaLabel}
          id={`${id}-open`}
          onClick={() => {
            if (status !== "inactive") {
              this.toggleOpen();
              document.activeElement.blur();
            }
          }}
          onKeyUp={e => {
            if (e.keyCode === 27 && this.state.isOpen) {
              this.toggleOpen();
            }
            if (e.keyCode === 13 || e.keyCode === 40) {
              this.toggleOpen();
            }
          }}
          className={`${icon && !label ? "info-icon" : ""}${
            this.state.isOpen ? " open" : ""
          }${extraLabel ? " with-label" : ""}`}
          aria-haspopup="true"
          aria-expanded={this.state.isOpen}
        >
          {icon && !iconAfter && icon}
          <span className="text">
            <span className="active" />
            {extraLabel && <Label>{extraLabel}</Label>}
            {label && label}
          </span>{" "}
          {icon && iconAfter && icon}
          {withArrow && (
            <span className="chevron">
              <ChevronIcon />
            </span>
          )}
        </ActionButtonToggle>
        <ActionWindow
          id={`${id}-window`}
          className={`tooltip ${id}-window${inline ? " inline" : ""}`}
          open={this.state.isOpen}
        >
          <ListGroup aria-label="tooltip content">
            {withClose && <CloseBtn onClick={this.toggleOpen} />}
            <div className="tooltip-content">{children}</div>
          </ListGroup>
        </ActionWindow>
      </ActionButtonWrapper>
    );
  }
}

ActionDropdown.propTypes = {
  label: PropTypes.string,
  extraLabel: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  inline: PropTypes.bool,
  withArrow: PropTypes.bool,
  icon: PropTypes.node,
  id: PropTypes.string,
  status: PropTypes.string,
  iconAfter: PropTypes.bool,
  withClose: PropTypes.bool
};

export default ActionDropdown;
