import React from "react";
import PropTypes from "prop-types";

const PushIcon = ({ color = "#fff" }) => (
  <svg
    className="icon-push"
    width="20px"
    height="12px"
    viewBox="0 0 20 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    alt="Push icon"
  >
    <defs />
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-86.000000, -1288.000000)"
        className="fill-target"
        fill={color}
      >
        <g transform="translate(50.000000, 920.000000)">
          <g transform="translate(1.000000, 334.000000)">
            <g transform="translate(29.000000, 24.000000)">
              <path d="M13.4,20.6 L8.8,16 L13.4,11.4 L12,10 L6,16 L12,22 L13.4,20.6 L13.4,20.6 Z M18.6,20.6 L23.2,16 L18.6,11.4 L20,10 L26,16 L20,22 L18.6,20.6 L18.6,20.6 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

PushIcon.propTypes = {
  color: PropTypes.string
};

export default PushIcon;
