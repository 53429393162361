import React from "react";
import PropTypes from "prop-types";

const UserIcon = ({ color = "#fff" }) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="fill-target"
      d="M8 0C10.3 0 12 1.7 12 4C12 6.3 10.3 8 8 8C5.7 8 4 6.3 4 4C4 1.7 5.7 0 8 0Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="fill-target"
      d="M7.99995 18C5.09995 18 2.39995 16.8 0.59995 15C-0.20005 14.2 -0.10005 12.8 0.79995 12.1C2.59995 10.7 5.99995 10 7.99995 10C9.99995 10 13.3999 10.7 15.1999 12.1C16.0999 12.8 16.2999 14.2 15.3999 15C13.5999 16.8 10.9 18 7.99995 18Z"
      fill={color}
    />
  </svg>
);

UserIcon.propTypes = {
  color: PropTypes.string
};

export default UserIcon;
