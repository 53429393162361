import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import momentjs from "moment";
import { Link } from "react-router";
import ReactTooltip from "react-tooltip";

import Square from "../Square";
import InProgressStateIcon from "Icons/InProgressStateIcon";
import PendingStateIcon from "Icons/PendingStateIcon";
import SuccessStateIcon from "Icons/SuccessStateIcon";
import ChevronIcon from "Icons/ChevronIcon";
import WarningIcon from "Icons/WarningIcon";
import CommitList from "../CommitList";
import { dateAsUTC } from "Libs/utils";

const ActivityListItemBarLayout = styled.div`
  a {
    color: #38485e;
    box-sizing: border-box;
    display: inline-block;
    text-decoration: none;
    font-size: 15px;
    padding: 0 8px;
    margin: 0 -8px;
    border-radius: 4px;
    line-height: 24px;
    .UserAvatar--inner {
      color: #1a192b;
    }
    &:hover {
      color: ${props => props.theme.links};
      .UserAvatar--inner {
        color: #1a192b;
      }
    }
    &:focus {
      margin: 0 -9px;
      color: ${props => props.theme.links};
      border: 1px solid ${props => props.theme.links};
      outline: none;
    }
  }
  a.text-link {
    color: ${props => props.theme.links};
  }
  a.commit-link {
    color: ${props => props.theme.links};
    margin-left: 8px;
    font-size: 14px;
    min-width: 100px;
    &:focus {
      margin-left: 15px;
    }
  }
  .activity-text {
    color: #38485e;
    font-size: 14px;
    width: calc(100% - 46px);
    @media (min-width: 1130px) {
      font-size: 15px;
    }
    .info,
    .environment {
      line-height: 24px;
    }
    .info {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (min-width: 768px) {
        white-space: normal;
      }
    }
    &:focus {
      outline: none;
      border: none;
      padding: 0;
      margin: 0;
    }
  }
  span.log-link-wrapper {
    width: 100%;
    display: none;
  }
  a.log-link {
    color: ${props => props.theme.links};
    font-size: 14px;
    min-width: 72px;
    padding: 0;
    margin-right: 8px;
    padding: 0 8px;
    &:hover {
      position: relative;
      &:after {
        content: "";
        width: calc(100% - 16px);
        position: absolute;
        height: 1px;
        background: ${props => props.theme.links};
        bottom: 2px;
        left: 8px;
        display: inline-block;
      }
    }
    &:focus {
      margin-right: 17px;
    }
    &:active {
      border: none;
      box-shadow: none;
      margin-right: 16px;
    }
  }
  .activity-item-right {
    line-height: 24px;
  }
  &.environment-push {
    .activity-item-left {
      position: relative;
    }
    .commit-link {
      position: absolute;
      left: 28px;
      bottom: 12px;
    }
    &.project-activity {
      .activity-text .environment {
        margin-left: 100px;
      }
    }
  }
  &.environment-backup,
  &.backups-activity {
    .activity-item-right {
      width: 284px;
      min-width: 284px;
      padding-right: 0;
      .status-icon {
        margin-left: 32px;
      }
    }
  }
  &.project-activity {
    .activity-item-left {
      line-height: 72px;
      min-height: 72px;
    }
    .activity-text .info {
      line-height: 24px;
      display: block;
    }
    .activity-text .environment {
      line-height: 24px;
      a.environmentLink {
        font-size: 12px;
      }
    }
    a.commit-link {
      align-self: flex-start;
      margin-top: 13px;
      &:focus {
        margin: 12px -1px -1px 7px;
      }
    }
  }
  > a {
    cursor: pointer;
  }
  @media (min-width: 768px) {
    .activity-item-left {
      padding-right: 24px;
    }
    &.environment-push {
      .activity-item-right {
        padding-right: 16px;
      }
      .commit-link {
        left: 72px;
      }
    }
    span.log-link-wrapper {
      display: inline-block;
    }
  }
  @media (min-width: 1280px) {
    .activity-item-left {
      padding-right: 64px;
    }
    a.log-link {
      margin-right: 16px;
    }
    &.environment-push {
      .activity-item-right {
        padding-right: 16px;
      }
      .commit-link {
        position: relative;
        left: 0;
        bottom: 0;
      }
      &.project-activity {
        .activity-text .environment {
          margin-left: 0;
        }
      }
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 30px;
`;

export const ActivityListItemBarStatus = styled.div`
  display: inline-block;
  height: 24px;
  width: 24px;
`;

const ActivityListItemBarLeftLayout = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 60px);
  line-height: 56px;
  min-height: 56px;
  box-sizing: border-box;
  .activity-icon {
    margin-right: 16px;
    border-radius: 4px;
    background: transparent;
    svg .fill-target {
      fill: #5f5e70;
    }
  }
  .avatar {
    display: none;
  }
  .UserAvatar--inner,
  .UserAvatar--img {
    border-radius: 4px !important;
    overflow: hidden;
  }
  @media (min-width: 768px) {
    width: calc(100% - 204px);
    .avatar {
      display: inline-block;
    }
    .activity-icon {
      background: ${props => props.theme.brandColor};
      svg .fill-target {
        fill: #fff;
      }
    }
  }
  @media (min-width: 1280px) {
    width: calc(100% - 256px);
    .activity-icon {
      margin-right: 32px;
      border-radius: 4px;
    }
  }
`;

const ActivityListItemBarRightLayout = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: flex-end;
  box-sizing: border-box;
  position: relative;

  .status,
  .status > span,
  .chevron {
    display: flex;
    align-items: center;
  }
  .commit-toggle {
    padding: 0;
    margin: 0 -10px 0 8px;
    display: none;
    > span {
      margin: 0;
    }
    .open svg {
      transform: rotate(180deg);
    }
    &:hover,
    &:focus,
    &:active {
      border: none;
      padding: 0;
      margin: 0 -10px 0 16px;
      svg > g > g {
        fill: ${props => props.theme.links};
      }
    }
  }
  .moment {
    font-size: 12px;
    color: #5f5e70;
    min-width: 60px;
    text-align: right;
    .place-bottom {
      margin-top: 0;
    }
  }
  @media (min-width: 768px) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 204px;
    min-width: 204px;
    line-height: 56px;
    height: 56px;
    padding-right: 48px;
    .commit-toggle {
      display: inline-block;
    }
    .moment {
      font-size: 13px;
      margin-right: 12px;
      text-align: left;
    }
  }
  @media (min-width: 1280px) {
    width: 256px;
    min-width: 256px;
    .commit-toggle {
      margin-left: 16px;
    }
    .moment {
      margin-right: 24px;
    }
  }
`;

class ActivityListItemBar extends React.Component {
  constructor(props) {
    super(props);
    this.openCommits = this.openCommits.bind(this);
    this.closeCommits = this.closeCommits.bind(this);
    this.state = {
      openCommits: false
    };
  }

  openCommits() {
    this.setState({
      openCommits: true
    });
  }
  closeCommits() {
    this.setState({
      openCommits: false
    });
  }
  getStateIcon(state, result) {
    let stateIcon = false;

    if (state === "in_progress") {
      stateIcon = <InProgressStateIcon />;
    }

    if (state === "pending") {
      stateIcon = <PendingStateIcon color="#EBBB1B" />;
    }

    if (state === "complete" && result === "success") {
      stateIcon = <SuccessStateIcon color="#00C89C" />;
    }

    if (state === "complete" && result === "failure") {
      stateIcon = <WarningIcon className="error" color="#ff8359" />;
    }

    return (
      <div className="status">
        {stateIcon && <span className={`status-${result}`}>{stateIcon}</span>}
      </div>
    );
  }

  render() {
    const {
      icon,
      title,
      state,
      result,
      link,
      linkCommits,
      openModal,
      moment,
      commits,
      id,
      activity,
      overrideClass,
      activityContext,
      log
    } = this.props;

    const activityClass = overrideClass
      ? overrideClass
      : activity && activity.type
        ? activity.type.replace(".", "-")
        : "";

    momentjs.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s",
        s: "%d sec",
        ss: "%d secs",
        m: "%d min",
        mm: "%d mins",
        h: "%d hr",
        hh: "%d hrs",
        d: "%d day",
        dd: "%d days",
        M: "%d month",
        MM: "%d months",
        y: "%d year",
        yy: "%d years"
      }
    });

    return (
      <ActivityListItemBarLayout
        result={result}
        className={`${result} ${activityClass} ${
          activityContext ? activityContext + "-activity" : ""
        }`}
      >
        <TitleWrapper>
          <ActivityListItemBarLeftLayout className="activity-item-left">
            {result === "failure" ? (
              <Square
                size={30}
                className={`activity-icon${result ? " " + result : ""}`}
              >
                {icon}
              </Square>
            ) : (
              <Square
                color={state !== "complete" ? "#4a495e" : ""}
                size={30}
                className={`activity-icon${result ? " " + result : ""}`}
              >
                {icon}
              </Square>
            )}
            <span
              className="activity-text"
              data-tip
              data-for={`no-log-activity-${id}`}
            >
              {title}
            </span>
            {link &&
              (activity.type && activity.type !== "environment.backup" ? (
                linkCommits && commits ? (
                  !this.state.openCommits && (
                    <Link
                      onClick={e => {
                        e.preventDefault();
                        this.openCommits();
                        document.activeElement.blur();
                      }}
                      className="commit-link text-link"
                      tabIndex="0"
                      href=""
                    >
                      + {commits && commits.length} commits
                    </Link>
                  )
                ) : (
                  <span>{link}</span>
                )
              ) : (
                ""
              ))}
          </ActivityListItemBarLeftLayout>
          <ActivityListItemBarRightLayout className="activity-item-right">
            <span className="moment">
              <span
                style={{ display: "inline-block" }}
                data-tip
                data-for={`moment-${momentjs(moment)}`}
              >
                {momentjs(moment).fromNow()}
                <ReactTooltip
                  id={`moment-${momentjs(moment)}`}
                  place="bottom"
                  type="dark"
                  effect="solid"
                >
                  {momentjs(moment).format("h:mm a on MMMM D, YYYY")} <br />
                  UTC: {dateAsUTC(moment)}
                </ReactTooltip>
              </span>
            </span>
            {log &&
              log !== "" && (
                <span className="log-link-wrapper">
                  <Link
                    id={`activity-link-${id}`}
                    onClick={e => {
                      e.preventDefault();
                      openModal();
                      document.activeElement.blur();
                    }}
                    tabIndex="0"
                    href=""
                    className="log-link"
                  >
                    View log
                  </Link>
                </span>
              )}
            {link &&
            activity.type &&
            activity.type === "environment.backup" &&
            result === "success" ? (
              <span>{link}</span>
            ) : (
              ""
            )}
            <span
              className="status-icon"
              data-tip
              data-for={`status-${momentjs(moment)}`}
            >
              {this.getStateIcon(state, result)}
              <ReactTooltip
                id={`status-${momentjs(moment)}`}
                place="bottom"
                effect="solid"
              >
                {state === "complete" ? result : state}
              </ReactTooltip>
            </span>
            {commits && (
              <Link
                onClick={e => {
                  e.preventDefault();
                  if (this.state.openCommits) {
                    this.closeCommits();
                  } else {
                    this.openCommits();
                  }
                  document.activeElement.blur();
                }}
                className="commit-toggle"
                tabIndex="0"
                href=""
              >
                <ChevronIcon
                  className={this.state.openCommits ? "open" : ""}
                  color="#4a495e"
                  animate={true}
                />
              </Link>
            )}
          </ActivityListItemBarRightLayout>
        </TitleWrapper>
        {commits &&
          this.state.openCommits && (
            <CommitList
              commits={commits}
              result={result}
              isOpen={this.state.openCommits}
              close={this.closeCommits}
            />
          )}
      </ActivityListItemBarLayout>
    );
  }
}

ActivityListItemBar.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.node,
  state: PropTypes.string,
  moment: PropTypes.string,
  result: PropTypes.string,
  link: PropTypes.node,
  linkCommits: PropTypes.bool,
  openModal: PropTypes.func,
  commits: PropTypes.array,
  id: PropTypes.string,
  activity: PropTypes.object,
  overrideClass: PropTypes.string,
  activityContext: PropTypes.string,
  log: PropTypes.string
};

export default ActivityListItemBar;
