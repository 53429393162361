import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";

import ActivityListItem from "../../ActivityListItem";
import ActivityTitle from "../../ActivityTitle";
import UserIcon from "../../../icons/UserIcon";

const EnvironmentAccessAddActivity = props => (
  <ActivityListItem
    icon={<UserIcon color="#ffffff" size={20} />}
    title={
      <ActivityTitle
        name={props.activity.payload.user.display_name}
        primaryText="added"
        primaryTitle={props.activity.payload.access.display_name}
        secondaryText="to"
        secondaryTitle={
          <Link
            to={`/${props.organizationId}/${
              props.activity.project
            }/${encodeURIComponent(props.activity.payload.environment.id)}`}
          >
            {props.activity.payload.environment.title}
          </Link>
        }
        organization={props.organizationId}
        project={props.activity.project}
        environment={
          props.activity.payload.environment
            ? props.activity.payload.environment.title
            : ""
        }
        environmentId={
          props.activity.payload.environment
            ? props.activity.payload.environment.id
            : ""
        }
        activityContext={props.activityContext}
      />
    }
    state={props.activity.state}
    moment={props.activity.created_at}
    log={props.activity.log}
    {...props}
  />
);

EnvironmentAccessAddActivity.propTypes = {
  activity: PropTypes.object,
  organizationId: PropTypes.string,
  activityContext: PropTypes.string
};

export default EnvironmentAccessAddActivity;
