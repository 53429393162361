import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import momentjs from "moment";

import UserAvatar from "react-user-avatar";

const CommitListItemLayout = styled.div`
  list-style: none;
  padding: 0;
  line-height: 24px
  margin-bottom: 24px;
  .avatar {
    margin-right: 16px;
  }
  .UserAvatar {
    font-size: 12px;
  }
  .UserAvatar--inner,
  .UserAvatar--img {
    border-radius: 4px !important;
    overflow: hidden;
    text-transform: uppercase;
    color: #1a192b;
    font-weight: 600;
  }
  .name {
    color: #4a495e;
  }
  .moment-wrapper {
    font-size: 13px;
    font-weight: 600;
    color: #5f5e70;
    width: 164px;
    min-width: 164px;
    .moment {
      display: inline-block;
    }
  }
`;

const ShaCell = styled.span`
  width: 90px;
  font-weight: 600;
  display: inline-block;
`;

const MessageCell = styled.span`
  width: 100%;
  margin-right: 20px;
`;

const UserInfo = styled.div`
  display: flex;
  float: left;
`;

class CommitListItem extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { sha, message, name, userAvatar, date } = this.props;
    const clipName = name ? name.split(/[\s.]+/) : "";
    return (
      <CommitListItemLayout key={sha}>
        <UserInfo>
          <span data-tip data-for={`${sha}-name`} className="avatar">
            <UserAvatar
              name={
                clipName.length > 2
                  ? clipName.slice(0, 2).join(" ")
                  : name
                    ? name
                    : "user"
              }
              src={userAvatar}
              size="24"
              colors={[
                "#ffcdd2",
                "#e1bee7",
                "#c5cae9",
                "#b3e5fc",
                "#b2dfdb",
                "#dcedc8",
                "#fff9c4",
                "#ffe0b2",
                "#d7ccc8",
                "#f8bbd0",
                "#d1c4e9",
                "#bbdefb",
                "#b2ebf2",
                "#c8e6c9",
                "#f0f4c3",
                "#ffecd3",
                "#ffccbc"
              ]}
            />
            <ReactTooltip
              id={`${sha}-name`}
              place="bottom"
              type="dark"
              effect="solid"
            >
              {name}
            </ReactTooltip>
          </span>
        </UserInfo>
        <ShaCell>{sha.substring(0, 7)}</ShaCell>
        <MessageCell>{message}</MessageCell>
        {date && (
          <span className="moment-wrapper">
            <span className="moment" data-tip data-for={`${sha}-date`}>
              {momentjs(date).fromNow()}
              <ReactTooltip
                id={`${sha}-date`}
                place="bottom"
                type="dark"
                effect="solid"
              >
                {momentjs(date).format("h:mm a on MMMM D, YYYY")}
              </ReactTooltip>
            </span>
          </span>
        )}
      </CommitListItemLayout>
    );
  }
}

CommitListItem.propTypes = {
  sha: PropTypes.string,
  message: PropTypes.string,
  name: PropTypes.string,
  userAvatar: PropTypes.string,
  date: PropTypes.string
};

export default CommitListItem;
