import React from "react";

const Chevron = id => (
  <svg
    width="108"
    height="100"
    viewBox="0 0 108 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath={`url(#${id}-clip0)`}>
      <path
        opacity="0.3"
        d="M82.5596 16.7989H77.7339C74.6277 16.7989 72.1079 14.279 72.1079 11.1729C72.1079 8.06669 74.6277 5.54688 77.7339 5.54688H82.5596C85.6658 5.54688 88.1856 8.06669 88.1856 11.1729C88.1856 14.279 85.6658 16.7989 82.5596 16.7989Z"
        fill="#5792FF"
      />
      <path
        opacity="0.3"
        d="M35.0161 11.252H30.1904C27.0843 11.252 24.5645 8.73217 24.5645 5.62599C24.5645 2.51981 27.0843 0 30.1904 0H35.0161C38.1223 0 40.6421 2.51981 40.6421 5.62599C40.6421 8.73217 38.1223 11.252 35.0161 11.252Z"
        fill="#5792FF"
      />
      <path
        d="M53.3203 99.9999C80.4518 99.9999 102.449 96.45 102.449 92.076C102.449 87.702 80.4518 84.1521 53.3203 84.1521C26.1887 84.1521 4.19189 87.702 4.19189 92.076C4.19189 96.45 26.1808 99.9999 53.3203 99.9999Z"
        fill="#C9D0E4"
      />
      <path
        opacity="0.3"
        d="M85.0158 51.664H31.1331C30.2615 51.664 29.5483 50.9508 29.5483 50.0792V47.5435C29.5483 46.6719 30.2615 45.9587 31.1331 45.9587H85.0158C85.8875 45.9587 86.6006 46.6719 86.6006 47.5435V50.0792C86.6006 50.9508 85.8875 51.664 85.0158 51.664Z"
        fill="#4786FF"
      />
      <path
        d="M81.0539 88.1142H27.1712C26.2996 88.1142 25.5864 87.401 25.5864 86.5294V83.9937C25.5864 83.1221 26.2996 82.4089 27.1712 82.4089H81.0539C81.9256 82.4089 82.6387 83.1221 82.6387 83.9937V86.5294C82.6387 87.401 81.9256 88.1142 81.0539 88.1142Z"
        fill="white"
      />
      <path
        d="M103.439 60.3804V66.7195"
        stroke="#C9D0E4"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M100.27 63.7085C100.555 63.7085 106.609 63.7085 106.609 63.7085"
        stroke="#C9D0E4"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M80.2615 88.2726H26.3787C25.5071 88.2726 24.7939 87.5594 24.7939 86.6878V49.9207C24.7939 49.0491 25.5071 48.3359 26.3787 48.3359H80.2615C81.1331 48.3359 81.8462 49.0491 81.8462 49.9207V86.6878C81.8462 87.5594 81.1331 88.2726 80.2615 88.2726Z"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M101.577 41.3631H96.7515C93.6453 41.3631 91.1255 38.8433 91.1255 35.7371C91.1255 32.6309 93.6453 30.1111 96.7515 30.1111H101.577C104.683 30.1111 107.203 32.6309 107.203 35.7371C107.203 38.8433 104.683 41.3631 101.577 41.3631Z"
        fill="white"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M79.3896 19.1758H74.564C71.4578 19.1758 68.938 16.656 68.938 13.5498C68.938 10.4436 71.4578 7.92383 74.564 7.92383H79.3896C82.4958 7.92383 85.0156 10.4436 85.0156 13.5498C85.0156 16.656 82.4958 19.1758 79.3896 19.1758Z"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M55.6182 38.9859H50.7925C47.6863 38.9859 45.1665 36.4661 45.1665 33.3599C45.1665 30.2537 47.6863 27.7339 50.7925 27.7339H55.6182C58.7244 27.7339 61.2442 30.2537 61.2442 33.3599C61.2442 36.4661 58.7244 38.9859 55.6182 38.9859Z"
        fill="white"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M32.6387 13.6292H27.813C24.7068 13.6292 22.187 11.1094 22.187 8.00319C22.187 4.89701 24.7068 2.3772 27.813 2.3772H32.6387C35.7449 2.3772 38.2647 4.89701 38.2647 8.00319C38.2647 11.1094 35.7449 13.6292 32.6387 13.6292Z"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M11.2441 38.9859H6.41847C3.31229 38.9859 0.79248 36.4661 0.79248 33.3599C0.79248 30.2537 3.31229 27.7339 6.41847 27.7339H11.2441C14.3503 27.7339 16.8701 30.2537 16.8701 33.3599C16.8701 36.4661 14.3503 38.9859 11.2441 38.9859Z"
        fill="white"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M27.7655 51.6641C28.2032 51.6641 28.5579 51.3094 28.5579 50.8717C28.5579 50.4341 28.2032 50.0793 27.7655 50.0793C27.3279 50.0793 26.9731 50.4341 26.9731 50.8717C26.9731 51.3094 27.3279 51.6641 27.7655 51.6641Z"
        fill="#4a495e"
      />
      <path
        d="M30.1425 51.6641C30.5801 51.6641 30.9349 51.3094 30.9349 50.8717C30.9349 50.4341 30.5801 50.0793 30.1425 50.0793C29.7049 50.0793 29.3501 50.4341 29.3501 50.8717C29.3501 51.3094 29.7049 51.6641 30.1425 51.6641Z"
        fill="#4a495e"
      />
      <path
        d="M90.364 35.0239C89.643 35.0239 88.8506 35.1349 88.0186 35.3488"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M83.5259 37.417C81.5528 38.7561 79.762 40.6262 78.7002 42.9796"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="6.27 6.27"
      />
      <path
        d="M77.9238 45.3408C77.7653 46.0936 77.686 46.8781 77.686 47.7021"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.4644 35.0239C18.1854 35.0239 18.9778 35.1349 19.8098 35.3488"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M24.3027 37.417C26.2758 38.7561 28.0666 40.6262 29.1284 42.9796"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="6.27 6.27"
      />
      <path
        d="M29.897 45.3408C30.0554 46.0936 30.1347 46.8781 30.1347 47.7021"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M52.6069 39.3027V48.1458"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="6 6"
      />
      <path
        d="M76.8225 19.5088C76.8225 19.5088 66.4263 33.5341 66.4263 48.1459"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="6 6"
      />
      <path
        d="M30.3091 13.8748C30.3091 19.1759 35.2853 37.7971 45.1902 47.702"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="6 6"
      />
      <path
        d="M25.1904 53.4548H82.1318"
        stroke="#4a495e"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M4.00937 61.1728C4.87587 61.1728 5.57831 60.4704 5.57831 59.6039C5.57831 58.7374 4.87587 58.0349 4.00937 58.0349C3.14287 58.0349 2.44043 58.7374 2.44043 59.6039C2.44043 60.4704 3.14287 61.1728 4.00937 61.1728Z"
        fill="#5792FF"
      />
      <path
        d="M3.73242 62.1157C5.18971 62.1157 6.37109 60.9343 6.37109 59.477C6.37109 58.0197 5.18971 56.8384 3.73242 56.8384C2.27512 56.8384 1.09375 58.0197 1.09375 59.477C1.09375 60.9343 2.27512 62.1157 3.73242 62.1157Z"
        stroke="#C9D0E4"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id={`${id}-clip0`}>
        <rect width="107.995" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Chevron;
