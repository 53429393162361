import React from "react";
import PropTypes from "prop-types";
import momentjs from "moment";
import styled from "styled-components";
import { Link } from "react-router";

import ActivityListItem from "../ActivityListItem";
import ActivityTitle from "../ActivityTitle";
import BackupIcon from "../../icons/BackupIcon";

import ModalConfirmDelete from "Components/ModalConfirmDelete";

const Message = styled.p`
  color: #4b6180;
  font-size: 15px;
  margin: 0 0 8px;
  &:nth-child(2) {
    margin-bottom: 32px;
  }
`;
const Note = styled.p`
  color: #38485e;
  font-size: 13px;
  font-weight: 600;
`;
// Import client from here
class EnvironmentBackupActivity extends React.Component {
  constructor(props) {
    super(props);
    this.openConfirmModal = this.openConfirmModal.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
    this.state = {
      openConfirmModal: false
    };
  }

  openConfirmModal() {
    this.setState({
      openConfirmModal: true
    });
  }

  closeConfirmModal() {
    this.setState({
      openConfirmModal: false
    });
  }
  render() {
    const { activity, activityContext, organizationId } = this.props;
    return (
      <React.Fragment>
        <ActivityListItem
          icon={<BackupIcon color="#ffffff" size={40} />}
          title={
            <ActivityTitle
              name={activity.payload.user.display_name}
              primaryText={
                activity.state !== "complete"
                  ? "creating_backup"
                  : activity.result !== "failure"
                    ? "created_backup"
                    : "attempt_create_backup"
              }
              primaryTitle={
                activity.payload.backup_name ? activity.payload.backup_name : ""
              }
              secondaryText="of"
              secondaryTitle={
                <Link
                  to={`/${organizationId}/${
                    activity.project
                  }/${encodeURIComponent(activity.payload.environment.id)}`}
                >
                  {activity.payload.environment.title}
                </Link>
              }
              organization={organizationId}
              project={activity.project}
              environment={
                activity.payload.environment
                  ? activity.payload.environment.title
                  : ""
              }
              environmentId={
                activity.payload.environment
                  ? activity.payload.environment.id
                  : ""
              }
              userId={activity.payload.user.id}
              activityContext={activityContext}
            />
          }
          link={
            activity.payload.backup_name ? (
              <span className="activity-button-wrapper">
                <Link
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.openConfirmModal();
                  }}
                  className="text-link"
                >
                  Restore
                </Link>
              </span>
            ) : (
              ""
            )
          }
          state={activity.state}
          moment={activity.created_at}
          result={activity.result}
          log={activity.log}
          {...this.props}
        />
        <ModalConfirmDelete
          isOpen={this.state.openConfirmModal}
          closeModal={this.closeConfirmModal}
          deleteFunction={() => {
            activity.restore();
            this.closeConfirmModal();
          }}
          modalSize="medium"
          title="Restore a previous backup?"
          body={
            <React.Fragment>
              <Message>
                Are you sure you want to restore the backup{" "}
                <strong>{activity.payload.backup_name}</strong> created{" "}
                {momentjs(activity.created_at).fromNow()}?
              </Message>
              <Message>
                This will delete and then overwrite all data from all running
                services and files of the
                {activity.payload.environment.title} environment.
              </Message>
              <Note>Note: this action can not be undone.</Note>
            </React.Fragment>
          }
          deleteText="Yes, restore"
        />
      </React.Fragment>
    );
  }
}

EnvironmentBackupActivity.propTypes = {
  activity: PropTypes.object,
  organizationId: PropTypes.string,
  activityContext: PropTypes.string
};

export default EnvironmentBackupActivity;
