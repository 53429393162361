import React from "react";
import ReactDOM from "react-dom";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router";
import CustomScroll from "react-custom-scroll";
import { LiveMessage } from "react-aria-live";

import Node from "./Node";
import Menu from "./Menu";
import List from "./List";
import ToggleTree from "../ToggleTree";
import ChevronIcon from "Icons/ChevronIcon";
import Label from "Components/fields/Label";
import CheckboxField from "Components/fields/CheckboxField";
import RadioField from "Components/fields/RadioField";
import SearchInput from "Components/SearchInput";
import ActionDropdown from "Components/ActionDropdown";
import IconFilter from "Icons/IconFilter";
import Heading6 from "Components/styleguide/Heading6";

const expandOut = keyframes`
  0% {
    width: 68px;
  }
  100% {
    width: 176px;
  }
`;

const expandIn = keyframes`
  0% {
    width: 176px;
  }
  100% {
    width: 68px;
  }
`;

const Layout = styled.div`
  height: 100%;
  position: relative;
  .rcs-custom-scroll {
    margin-right: -30px;
    .rcs-inner-container > div {
      margin-right: 50px !important;
    }
  }
  hr {
    background: #c9d0e4;
    box-shadow: none;
    height: 1px;
    border: none;
    margin: 16px 0;
    &.full-width {
      margin: 0 -32px 16px;
    }
  }
  .show-all {
    text-align: center;
    margin-bottom: -16px;
    button.outline {
      margin: 0;
    }
  }
  .environment-row.inactive {
    display: none;
  }
  .show-inactive .environment-row.inactive {
    display: block;
  }
  .hide-active .environment-row.active {
    display: none;
  }
  .environment-row.inactive.active-children,
  .hide-active .environment-row.active.inactive-children,
  .hide-active .environment-row.root.active,
  .environment-row.root.inactive {
    display: block !important;
  }
  .hide-all .environment-row.active,
  .hide-all .environment-row.inactive,
  .hide-all .environment-row.active.inactive-children,
  .hide-all .environment-row.inactive.active-children {
    display: none !important;
  }
  .environments-list {
    a.label.active {
      span.chevron svg path {
        fill: ${props => props.theme.links};
      }
    }
    .environment-row.active ~ .environment-row.active {
      border-top: 1px solid #e7e7e7;
    }
    &.show-inactive {
      .environment-row ~ .environment-row {
        border-top: 1px solid #e7e7e7;
      }
    }
    @media (min-width: 600px) {
      .website-link {
        right: 108px;
      }
    }
    @media (min-width: 1280px) {
      .website-link {
        right: 188px;
      }
    }
  }
  .compact {
    .depth-0,
    .depth-1 {
      .list-bar-left {
        line-height: 24px;
        .icon {
          min-width: 24px;
          width: 24px;
          height: 24px;
        }
      }
    }
    .environment-row-item {
      margin-bottom: 8px;
    }
  }
  .rcs-custom-scroll {
    .rcs-inner-container {
      padding: 8px 0;
      box-sizing: border-box;
    }
    .rcs-custom-scrollbar {
      .rcs-inner-handle {
        background: #4a495e;
        width: 4px;
        border-radius: 4px;
        opacity: 1;
      }
    }
  }
`;

const ActionLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  width: auto;
  position: absolute;
  right: 0;
  top: -64px;
  margin-bottom: 0;
  .checkbox-field,
  .checkbox-field label {
    margin: 0;
  }
  .checkbox-field {
    margin-bottom: 8px;
    &.checked {
      label {
        color: ${props => props.theme.links};
      }
    }
  }
  .radio-field {
    &.checked {
      label {
        color: ${props => props.theme.links};
      }
    }
  }
  .search-wrapper {
    height: inherit;
    margin-right: 8px;
    display: none;
    input.search {
      width: 86px;
      animation: ${expandIn} 0.18s linear 1;
      padding: 0 8px 0 32px;
      &.active {
        width: 176px;
        animation: ${expandOut} 0.18s linear 1;
      }
    }
  }
  .environments-filter-window {
    width: 300px;
    right: 50%;
    margin-right: -143px;
    h6 {
      font-weight: 600;
      &.title {
        font-weight: bold;
      }
    }
  }
  .info-button {
    > button {
      padding: 0 4px !important;
      border: none;
      width: auto;
      margin-right: 16px;
      @media (min-width: 1280px) {
        width: 70px;
      }
      &:hover,
      &.open {
        svg {
          transform: none;
          rect {
            fill: ${props => props.theme.links};
          }
        }
      }
    }
    .text {
      display: none;
      @media (min-width: 1280px) {
        display: inline-block;
      }
    }
  }
  hr {
    width: calc(100% + 32px);
    margin-left: -16px;
  }
  @media (min-width: 580px) {
    .search-wrapper {
      display: inline-block;
    }
  }
  @media (min-width: 900px) and (max-width: 1000px) {
    width: 50%;
    .checkbox-field {
      margin-bottom: 8px;
    }
  }
`;

const TreeWrapper = styled.div`
  padding: 0;
  ol {
    margin: 0;
    padding: 0;
  }
  .environments-list {
    padding-top: 32px;
  }
  .search-results {
    .sort-heading {
      display: none;
    }
    .environment-row {
      padding: 0 4px 16px;
      a {
        margin: -4px;
        border-radius: 4px;
        padding: 4px;
        height: inherit;
        line-height: inherit;
      }
      .info-button.website-link {
        top: 0;
      }
    }
  }
  .environments-list.search-results {
    .environment-row ~ .environment-row {
      &.active,
      &.inactive {
        border-top: none;
      }
    }
  }
  &.partial {
    max-height: 706px;
    overflow: hidden;
    padding: 4px 4px 0;
    margin: -16px -4px 0;
  }
  @media (max-width: 1130px) {
    li.root li.environment-row {
      padding-left: 32px !important;
      .vertical-line:after {
        left: 43px !important;
        top: 38px !important;
        height: calc(100% - 44px);
      }
      .environment-row-item {
        margin-bottom: 16px;
      }
      li.environment-row {
        padding-left: 24px !important;
        .vertical-line:after {
          left: 35px !important;
          top: 34px !important;
          height: calc(100% - 40px);
        }
        .horizontal-line:after {
          left: 12px;
          width: 14px;
        }
      }
    }
  }
`;

const EnvironmentsMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 0;
  margin: 0 -20px -20px;
  clear: both;
  .grid {
    margin: 0;
    border-top: 1px solid #eee;
    > div {
      padding: 15px;
      width: 100%;
      margin: 0;
    }
    a {
      white-space: nowrap;
      color: ${props => props.theme.links};
      font-weight: 600;
    }
  }
  .is-root.inactive,
  &.global-inactive .inactive,
  &.global-expanded .children {
    display: block;
  }
`;

const SortWrapper = styled.div`
  justify-content: space-between;
  margin: -32px -32px 0;
  padding: 0 32px 8px;
  color: #38485e;
  border-bottom: 1px solid #e7e7e7;
  display: none;
  @media (min-width: 1025px) {
    display: flex;
  }
  a {
    color: #38485e;
    text-decoration: none;
    display: flex;
    align-items: center;
    label {
      margin: 0;
    }
    &:focus {
      outline: none;
      label {
        color: ${props => props.theme.links};
      }
    }
  }
  svg {
    width: 24px;
    > g > g {
      fill: ${props => props.theme.brandColor};
    }
  }
  .descend svg {
    transform: rotate(180deg);
  }
  .label {
    cursor: pointer;
    &.active {
      padding-right: 0;
    }
    &.label-name {
      width: 100%;
    }
    &.label-updated {
      padding-right: 0;
      display: flex;
      min-width: 76px;
      @media (min-width: 1280px) {
        min-width: 120px;
      }
    }
    &.label-url {
      min-width: 28px;
      margin: 0;
      display: flex;
      align-items: center;
      @media (min-width: 600px) {
        min-width: 40px;
      }
      @media (min-width: 1025px) {
        min-width: 60px;
      }
      @media (min-width: 1280px) {
        min-width: 96px;
      }
    }
  }
`;

const getAllEnvironments = data => {
  if (!data) {
    return [];
  }

  return data.reduce((acc, current) => {
    if (!current) {
      return acc;
    }

    return acc.concat(current, getAllEnvironments(current.children));
  }, []);
};

class EnvironmentTree extends React.Component {
  constructor() {
    super();
    this.toggleLayout = this.toggleLayout.bind(this);
    this.openTooltip = this.openTooltip.bind(this);
    this.closeTooltip = this.closeTooltip.bind(this);
    this.toggleSort = this.toggleSort.bind(this);
    this.showAll = this.showAll.bind(this);
    this.showPartial = this.showPartial.bind(this);
    this.toggleInactive = this.toggleInactive.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.countDisplayed = this.countDisplayed.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);

    this.handleClickOutside = e => {
      if (!ReactDOM.findDOMNode(this).contains(e.target)) {
        this.closeTooltip();
      }
    };

    this.state = {
      treeLayout: true,
      sortType: "name",
      sortOrder: "ascend",
      sortedEnvironments: [],
      partial: false,
      showInactive: false,
      showActive: true,
      titleFilter: ""
    };
  }

  componentDidMount() {
    this.setState({
      partial: true
    });
    document.addEventListener("click", this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  toggleLayout(layout) {
    this.setState({
      treeLayout: layout
    });
  }

  openTooltip(title) {
    this.setState({
      openedTooltip: title
    });
  }

  closeTooltip() {
    this.setState({
      openedTooltip: ""
    });
  }

  toggleSort(value) {
    this.setState(prevState => {
      if (prevState.sortOrder === "ascend") {
        return {
          sortOrder: "descend"
        };
      } else {
        return {
          sortOrder: "ascend"
        };
      }
    });

    this.setState({
      sortType: value,
      treeLayout: false
    });
  }

  showAll() {
    this.setState({
      partial: false
    });
  }

  showPartial() {
    this.setState({
      partial: true
    });
  }

  toggleInactive(event) {
    this.setState({
      showInactive: event.target.checked
    });
  }

  toggleActive(event) {
    this.setState({
      showActive: event.target.checked
    });
  }

  countDisplayed(list) {
    const inactive = list.filter(obj => {
      return obj.status === "inactive";
    });
    return list.length - inactive.length;
  }

  onSearchChange(event) {
    this.setState({
      titleFilter: event.target.value
    });
  }

  render() {
    const environments = getAllEnvironments(this.props.data);
    const { environmentName } = this.props;
    const { openedTooltip, showInactive, showActive } = this.state;
    const activeEnvironments = environments.filter(i => i.status !== "inactive")
      .length;
    const compactView =
      (showInactive && environments.length > 19) ||
      (!showInactive && activeEnvironments > 19);

    return (
      <Layout>
        {this.state.treeLayout ? (
          <LiveMessage
            message="displaying environments as a list"
            aria-live="polite"
          />
        ) : (
          <LiveMessage
            message="displaying environments as a tree"
            aria-live="polite"
          />
        )}
        {showInactive ? (
          <LiveMessage
            message="inactive environments visible"
            aria-live="polite"
          />
        ) : (
          <LiveMessage
            message="inactive environments hidden"
            aria-live="polite"
          />
        )}
        {showActive ? (
          <LiveMessage
            message="active environments are visible"
            aria-live="polite"
          />
        ) : (
          <LiveMessage
            message="active environments are hidden"
            aria-live="polite"
          />
        )}

        {this.state.titleFilter && (
          <LiveMessage
            message="environment list has changed based on search"
            aria-live="polite"
          />
        )}
        {this.props.layout === "menu" ? (
          <EnvironmentsMenuWrapper className="environments-menu">
            <Menu
              id="environment-branch"
              list={environments}
              tree={this.props.data}
              organizationId={this.props.organizationId}
              currentEnvironment={environmentName}
            />
          </EnvironmentsMenuWrapper>
        ) : (
          <ActionLinks
            className={`toggle-layout${
              this.state.treeLayout ? " tree-layout" : " list-layout"
            }`}
          >
            <SearchInput
              placeholder="Search"
              className="float"
              onChange={this.onSearchChange}
              value={this.state.titleFilter}
              id="project-environment-search"
            />
            <ActionDropdown
              id="environments-filter"
              label="Filter"
              ariaLabel="Filter"
              icon={
                <span className="icon">
                  <IconFilter />
                </span>
              }
              iconAfter={true}
              withArrow={false}
              withClose={true}
            >
              <Heading6 className="title">Filter environments</Heading6>
              <hr />
              <Heading6>Show</Heading6>
              <CheckboxField
                forId="toggle-inactive"
                label="Inactive"
                type="checkbox"
                value={showInactive}
                onChange={this.toggleInactive}
              />
              <CheckboxField
                forId="toggle-active"
                label="Active"
                type="checkbox"
                value={showActive}
                onChange={this.toggleActive}
              />
              <hr />
              <Heading6>Sort by</Heading6>
              <RadioField
                forId="sort-alphabetically"
                name="sort"
                label="Alphabetically A-Z"
                value={this.state.sortType === "name" && !this.state.treeLayout}
                onChange={() => {
                  this.toggleSort("name");
                }}
                disabled={this.state.treeLayout}
              />
              <RadioField
                forId="sort-by-created"
                name="sort"
                label="Date created"
                value={
                  this.state.sortType === "created" && !this.state.treeLayout
                }
                onChange={() => {
                  this.toggleSort("created");
                }}
                disabled={this.state.treeLayout}
              />
            </ActionDropdown>
            <ToggleTree
              toggleLayout={this.toggleLayout}
              gridLayout={this.state.treeLayout}
            />
          </ActionLinks>
        )}
        {this.props.layout !== "menu" &&
          (!this.state.treeLayout || this.state.titleFilter) && (
            <TreeWrapper>
              <div
                className={`environments-list${
                  showActive ? "" : " hide-active"
                }${showInactive ? " show-inactive" : ""}${
                  compactView ? " compact" : ""
                }${!showActive && !showInactive ? " hide-all" : ""}${
                  this.state.titleFilter ? " search-results" : ""
                }`}
              >
                {!this.state.treeLayout && (
                  <SortWrapper className="sort-heading">
                    <Link
                      onClick={e => {
                        e.preventDefault();
                        this.toggleSort("name");
                      }}
                      className={`label label-name${
                        this.state.sortType === "name" ? " active" : ""
                      }`}
                      href="#"
                      tabIndex="0"
                    >
                      <Label>Name</Label>
                      {this.state.sortType === "name" && (
                        <span className={this.state.sortOrder}>
                          <ChevronIcon />
                        </span>
                      )}
                    </Link>
                    <Label className="label label-url">Url</Label>
                    <Link
                      onClick={e => {
                        e.preventDefault();
                        this.toggleSort("created");
                      }}
                      className={`label label-updated${
                        this.state.sortType === "created" ? " active" : ""
                      }`}
                      href="#"
                      tabIndex="0"
                    >
                      <Label>Created</Label>
                      {this.state.sortType === "created" && (
                        <span className={this.state.sortOrder}>
                          <ChevronIcon />
                        </span>
                      )}
                    </Link>
                  </SortWrapper>
                )}
                <div>
                  <CustomScroll heightRelativeToParent="706px">
                    {environments
                      .sort((a, b) => {
                        if (this.state.sortType === "created") {
                          if (this.state.sortOrder === "descend") {
                            if (a.created_at < b.created_at) return -1;
                            if (a.created_at > b.created_at) return 1;
                            return 0;
                          } else {
                            if (a.created_at > b.created_at) return -1;
                            if (a.created_at < b.created_at) return 1;
                            return 0;
                          }
                        } else {
                          if (this.state.sortOrder === "descend") {
                            if (a.title.toLowerCase() > b.title.toLowerCase())
                              return -1;
                            if (a.title.toLowerCase() < b.title.toLowerCase())
                              return 1;
                            return 0;
                          } else {
                            if (a.title.toLowerCase() < b.title.toLowerCase())
                              return -1;
                            if (a.title.toLowerCase() > b.title.toLowerCase())
                              return 1;
                            return 0;
                          }
                        }
                      })
                      .filter(node => {
                        if (this.state.titleFilter) {
                          return node.title
                            .toLowerCase()
                            .includes(this.state.titleFilter.toLowerCase());
                        }
                        return true;
                      })
                      .map(node => (
                        <List
                          key={node.title}
                          openTooltip={this.openTooltip}
                          closeTooltip={this.closeTooltip}
                          openedTooltip={openedTooltip}
                          node={node}
                          organizationId={this.props.organizationId}
                          push={this.props.router.push}
                          titleFilter={this.state.titleFilter}
                        />
                      ))}
                  </CustomScroll>
                </div>
              </div>
            </TreeWrapper>
          )}
        {this.props.layout !== "menu" &&
          this.state.treeLayout &&
          !this.state.titleFilter && (
            <CustomScroll heightRelativeToParent="706px">
              <TreeWrapper
                className={`${showActive ? "" : " hide-active"}${
                  showInactive ? " show-inactive" : ""
                }${!showActive && !showInactive ? " hide-all" : ""}${
                  compactView ? " compact" : ""
                }`}
              >
                <LiveMessage
                  message="displaying environments as a tree"
                  aria-live="polite"
                />
                <ol>
                  <Node
                    openTooltip={this.openTooltip}
                    closeTooltip={this.closeTooltip}
                    openedTooltip={openedTooltip}
                    nodes={this.props.data}
                    organizationId={this.props.organizationId}
                    push={this.props.router.push}
                    compactView={compactView}
                  />
                </ol>
              </TreeWrapper>
            </CustomScroll>
          )}
      </Layout>
    );
  }
}

EnvironmentTree.propTypes = {
  data: PropTypes.array,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  layout: PropTypes.string,
  environmentName: PropTypes.string,
  router: PropTypes.object
};

export default withRouter(EnvironmentTree);
