import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-right: -9px;
  & > div + div {
    margin-left: 8px;
  }
  .info-button {
    > button {
      width: 50px;
      padding: 0 !important;
      justify-content: center;
    }
    span.chevron {
      display: none;
    }
  }

  .tooltip-content {
    pre {
      line-height: 21px;
      font-size: 12px;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      white-space: normal;
      margin-right: 0;
    }
    .copyable-area + .copyable-area {
      margin-top: 16px;
    }
  }
  .disabled button {
    opacity: 0.5;
    cursor: not-allowed;
  }
  a {
    padding: 0 8px;
    line-height: 24px;
    margin: 0 -8px;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      border: 1px solid ${props => props.theme.buttonHover};
      text-decoration: none;
      margin: -1px -9px -1px;
      box-shadow: 0 0 2px ${props => props.theme.buttonHover};
      outline: none;
      border-radius: 4px;
    }
    &:active {
      box-shadow: none;
      border: none;
      margin: 0 -8px;
    }
  }

  @media (min-width: 768px) {
    margin-right: 0;
    .info-button {
      > button {
        width: 80px;
        padding: 0 4px 0 16px !important;
        justify-content: space-between;
      }
      span.chevron {
        display: flex;
      }
    }
  }
`;
