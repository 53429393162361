import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map, List } from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";

import { loadEnvironments } from "Reducers/environment";

import EnvironmentTree from "Components/environmentTree";
import Loading from "Components/Loading";

const getTreeData = (nodes, environments, depth = 0) => {
  return nodes.map(node => ({
    environmentId: node.id,
    projectId: node.project,
    organizationId: node.organization,
    created_at: node.created_at,
    updated_at: node.updated_at,
    title: node.title,
    isRoot: !node.parent,
    isDirty: node.status === "dirty",
    expanded: true,
    status: node.status,
    isMock: node.isMock,
    deployment_target: node.deployment_target,
    depth,
    children: getTreeData(
      environments.filter(environment => environment.parent === node.id).toJS(),
      environments,
      depth + 1
    )
  }));
};

class EnvironmentsTree extends React.Component {
  // eslint-disable-line
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.loadEnvironments();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.projectId !== this.props.projectId) {
      this.props.loadEnvironments();
    }
  }

  getRootEnvironments(environments) {
    const rootEnvironments = environments.filter(
      environment => !environment.parent
    );
    const environmentKeys = environments.map(env => env.name);
    const shouldMockRootEnvironments = rootEnvironments.toArray().length === 0;

    if (shouldMockRootEnvironments) {
      const mockKeys = [];
      const mockRootEnvironments = environments.reduce((mocks, value) => {
        const hasParentAvailable = environmentKeys.indexOf(value.parent) > -1;
        const hasMockCreated = mockKeys.indexOf(value.parent) > -1;

        if (hasParentAvailable || hasMockCreated) {
          return mocks;
        }

        const mock = {
          id: value.parent,
          environmentId: null,
          projectId: null,
          organizationId: null,
          updated_at: null,
          title: value.parent.charAt(0).toUpperCase() + value.parent.slice(1),
          isRoot: true,
          isDirty: false,
          expanded: true,
          status: null,
          deployment_target: "local",
          isMock: true
        };
        mockKeys.push(value.parent);
        return mocks.push(mock);
      }, new List());
      return mockRootEnvironments.valueSeq();
    }
    return rootEnvironments;
  }

  render() {
    const environments = this.props.environments.valueSeq();
    const layout = this.props.layout ? this.props.layout : "";

    if (!environments.size) {
      return <Loading iconOnly={true} />;
    }
    const rootEnvironments = this.getRootEnvironments(environments);
    const data = getTreeData(rootEnvironments, environments);
    return (
      <div>
        <EnvironmentTree
          data={data.toJS()}
          layout={layout}
          organizationId={this.props.organizationId}
          projectId={this.props.projectId}
          environmentName={this.props.environmentName}
        />
      </div>
    );
  }
}

EnvironmentsTree.propTypes = {
  environments: ImmutablePropTypes.map,
  loadEnvironments: PropTypes.func,
  environmentId: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  layout: PropTypes.string,
  environmentName: PropTypes.string
};

const mapStateToProps = (state, props) => {
  const environment = state.environment || new Map();

  return {
    environments: environment.getIn(
      ["data", props.organizationId, props.projectId],
      new Map()
    ),
    isLoading: environment.get("loading")
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  loadEnvironments: () =>
    dispatch(loadEnvironments(props.projectId, props.organizationId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnvironmentsTree);
