import React from "react";

const IconLayoutTree = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4375 7.5H12.5625V10.875H19.3125V12V16.5H18.1875V12H12.5625V16.5H14.25V21H9.75V16.5H11.4375V12H5.8125V16.5H7.5V21H3V16.5H4.6875V12V10.875H11.4375V7.5ZM9.75 7.5H14.25V3H9.75V7.5ZM16.5 21H21V16.5H16.5V21Z"
      fill="#5F5E70"
    />
  </svg>
);

export default IconLayoutTree;
