import React from "react";
import PropTypes from "prop-types";

import ActivityListItem from "../../ActivityListItem";
import ActivityTitle from "../../ActivityTitle";
import SystemIcon from "Icons/SystemIcon";

const ProjectVariableCreateActivity = props => (
  <ActivityListItem
    icon={<SystemIcon color="#ffffff" size={20} />}
    title={
      <ActivityTitle
        name={props.activity.payload.user.display_name}
        primaryText="deleted_project_variable"
        primaryTitle={props.activity.payload.variable.name}
      />
    }
    state={props.activity.state}
    moment={props.activity.created_at}
    result={props.activity.result}
    log={props.activity.log}
    {...props}
  />
);

ProjectVariableCreateActivity.propTypes = {
  activity: PropTypes.object,
  activityContext: PropTypes.string
};

export default ProjectVariableCreateActivity;
