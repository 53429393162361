import React from "react";
import PropTypes from "prop-types";

import ActivityListItem from "../ActivityListItem";
import ActivityTitle from "../ActivityTitle";
import PushIcon from "Icons/PushIcon";
import { Link } from "react-router";

const ProjectCreateActivity = props => (
  <React.Fragment>
    <ActivityListItem
      id={props.id}
      icon={<PushIcon color="#ffffff" size={40} />}
      title={
        <ActivityTitle
          name={props.activity.payload.user.display_name}
          primaryText="created_a_new_project"
          primaryTitle={
            <Link to={`/${props.organizationId}/${props.activity.project}`}>
              {props.activity.payload.outcome.title}
            </Link>
          }
        />
      }
      organization={props.organizationId}
      state={props.activity.state}
      moment={props.activity.created_at}
      commits={props.activity.payload.commits}
      result={props.activity.result}
      log={props.activity.log}
      {...props}
    />
  </React.Fragment>
);

ProjectCreateActivity.propTypes = {
  activity: PropTypes.object,
  canEditProject: PropTypes.bool,
  id: PropTypes.string,
  organizationId: PropTypes.string,
  activityContext: PropTypes.string
};

export default ProjectCreateActivity;
