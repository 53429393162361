const d3 = require("d3"),
  layout = require("dagre").layout;

module.exports = render;

// This design is based on http://bost.ocks.org/mike/chart/.
function render() {
  let createNodes = require("./create-nodes"),
    createEdgePaths = require("./create-edge-paths"),
    positionNodes = require("./position-nodes"),
    positioner = require("./positioner");

  let fn = function(svg, g, relayout) {
    g.graph().positioner = function(g) {
      positioner(g, fn.options);
    };
    normalizeGraph(g);
    preProcessGraph(g);

    let outputGroup = createOrSelectGroup(svg, "output"),
      edgePathsGroup = createOrSelectGroup(outputGroup, "edgePaths"),
      nodes = createNodes(createOrSelectGroup(outputGroup, "nodes"), g);

    if (relayout) {
      layout(g);
    }

    positionNodes(nodes, g);
    postProcessEdges(g);
    createEdgePaths(edgePathsGroup, g, fn.options);

    postProcessGraph(g);

    let shapeBBox = outputGroup.node().getBBox();
    g.graph().width = shapeBBox.width;
    g.graph().height = shapeBBox.height;
  };

  fn.options = {
    gridStepX: 60,
    gridStepY: 80,
    arcLength: 10,
    sameLayerOffset: 35,
    crossLayerOffset: 45
  };

  return fn;
}

let NODE_DEFAULT_ATTRS = {
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
  rx: 0,
  ry: 0,
  shape: "rect"
};

let EDGE_DEFAULT_ATTRS = {
  arrowhead: "normal",
  curve: d3.curveLinear
};

function normalizeGraph(g) {
  g.nodes().forEach(node => {
    let vNode = g.node(node);

    g.outEdges(node).forEach(e => {
      let wNode = g.node(e.w);
      let edge = g.edge(e);
      if (wNode.rank < vNode.rank) {
        edge.reversed = true;
        g.removeEdge(e);
        g.setEdge(e.w, e.v, edge);
      } else {
        edge.reversed = false;
      }
    });
  });
}

function preProcessGraph(g) {
  g.nodes().forEach(function(v) {
    let node = g.node(v);
    if (!node.label && !g.children(v).length) {
      node.label = v;
    }

    if (node.paddingX) {
      node = {
        paddingLeft: node.paddingX,
        paddingRight: node.paddingX,
        ...node
      };
    }

    if (node.paddingY) {
      node = {
        paddingTop: node.paddingY,
        paddingBottom: node.paddingY,
        ...node
      };
    }

    if (node.padding) {
      node = {
        paddingLeft: node.padding,
        paddingRight: node.padding,
        paddingTop: node.padding,
        paddingBottom: node.padding,
        ...node
      };
    }

    node = {
      ...NODE_DEFAULT_ATTRS,
      ...node
    };

    ["paddingLeft", "paddingRight", "paddingTop", "paddingBottom"].forEach(
      k => {
        node[k] = Number(node[k]);
      }
    );

    // Save dimensions for restore during post-processing
    if (node.width) {
      node._prevWidth = node.width;
    }
    if (node.height) {
      node._prevHeight = node.height;
    }
  });

  g.edges().forEach(function(e) {
    let edge = g.edge(e);
    if (!edge.label) {
      edge.label = "";
    }
    edge = { ...EDGE_DEFAULT_ATTRS, ...edge };
    edge = {
      ...EDGE_DEFAULT_ATTRS,
      ...edge
    };
  });
}

function postProcessEdges(g) {
  g.nodes().forEach(n => {
    let node = g.node(n);
    let edges = g.outEdges(n);

    let hasLeftEdge = false,
      hasRightEdge = false;

    edges.forEach(e => {
      let target = g.node(e.w);
      let edge = g.edge(e);
      if (target.rank == node.rank) {
        edge.class = "same";
        return;
      }

      if (target.x < node.x) {
        edge.class = "left";
        hasLeftEdge = true;
      } else if (target.x > node.x) {
        edge.class = "right";
        hasRightEdge = true;
      } else {
        edge.class = "middle";
      }
    });

    let shouldStraightCross = hasLeftEdge && hasRightEdge;
    edges.forEach(e => {
      g.edge(e).straightCross = shouldStraightCross;
    });
  });
}

function postProcessGraph(g) {
  g.nodes().forEach(v => {
    let node = g.node(v);

    // Restore original dimensions
    if (node._prevWidth) {
      node.width = node._prevWidth;
    } else {
      delete node.width;
    }

    if (node._prevHeight) {
      node.height = node._prevHeight;
    } else {
      delete node.height;
    }

    delete node._prevWidth;
    delete node._prevHeight;
  });
}

function createOrSelectGroup(root, name) {
  let selection = root.select("g." + name);
  if (selection.empty()) {
    selection = root.append("g").attr("class", name);
  }
  return selection;
}
