import React from "react";
import PropTypes from "prop-types";

import ActivityListItem from "../ActivityListItem";
import ActivityTitle from "../ActivityTitle";
import SystemIcon from "../../icons/SystemIcon";

const EnvironmentInitializeActivity = props => (
  <ActivityListItem
    icon={<SystemIcon color="#ffffff" size={20} />}
    title={
      <ActivityTitle
        name={props.activity.payload.user.display_name}
        primaryText="initialized_environment"
        primaryTitle={
          props.activity.payload.outcome && props.activity.payload.outcome.title
        }
        secondaryText="with_profile"
        secondaryTitle={props.activity.payload.profile}
        organization={props.organizationId}
        project={props.activity.project}
        environment={
          props.activity.payload.outcome && props.activity.payload.outcome.title
        }
        environmentId={
          props.activity.payload.environment
            ? props.activity.payload.environment.id
            : ""
        }
        userId={props.activity.payload.user.id}
        activityContext={props.activityContext}
      />
    }
    state={props.activity.state}
    moment={props.activity.created_at}
    result={props.activity.result}
    log={props.activity.log}
    {...props}
  />
);

EnvironmentInitializeActivity.propTypes = {
  activity: PropTypes.object,
  environmentPublicUrl: PropTypes.string,
  organizationId: PropTypes.string,
  activityContext: PropTypes.string
};

export default EnvironmentInitializeActivity;
