import styled from "styled-components";

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  position: relative;
  .title,
  .moment,
  .user-name,
  .commit-diff {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.inactive {
    .title,
    .link,
    .user-name,
    .commit-diff {
      opacity: 0.8;
    }
  }
  .moment:hover {
    opacity: 1;
  }
`;
