import styled from "styled-components";

export default styled.div`
  position: absolute;
  display: ${props => (props.open ? "block" : "none")};
  margin-top: 4px;
  right: 0;
  left: 0;
  font-size: 12px;
  z-index: 2;
  box-shadow: 0 6px 24px rgba(152, 160, 171, 0.4);
  border-radius: 2px 0 2px 2px;
  > section {
    padding: 16px;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0;
    overflow: hidden;
    box-shadow: none;
  }
  .close {
    float: right;
    margin: -6px -6px 0 0;
  }
  h3 {
    margin: 0 0 20px;
    font-size: 14px;
  }
  &.inline {
    padding: 0;
    top: 0;
    border: none;
    margin-top: -10px;
    margin-left: -10px;
  }
  @media (min-width: 960px) {
    left: auto;
    right: 0;
    width: 600px;
    &.environment-urls-window {
      width: 440px;
    }
  }
`;
