import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ActivityListItemBar from "./ActivityListItemBar";

const ActivityListItemLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
  margin-bottom: 1px;
  &:hover,
  &:focus-within {
    background-color: #f9f9f9;
  }
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 100%;
    left: 24px;
    height: 1px;
    width: calc(100% - 48px);
    background: #d8e2f0;
  }
  &:last-child::after {
    display: none;
  }
  @media (min-width: 1280px) {
    padding: 0 32px;
    &:after {
      left: 32px;
      width: calc(100% - 64px);
    }
  }
`;

const ActivityListItem = ({
  icon,
  title,
  state,
  moment,
  commits,
  result,
  openModal,
  link,
  linkCommits = false,
  activity,
  overrideClass,
  activityContext,
  log
}) => (
  <ActivityListItemLayout className="activity-row">
    <ActivityListItemBar
      icon={icon}
      title={title}
      status={status}
      moment={moment}
      state={state}
      result={result}
      openModal={openModal}
      commits={commits}
      link={link}
      linkCommits={linkCommits}
      activity={activity}
      overrideClass={overrideClass}
      activityContext={activityContext}
      log={log}
    />
  </ActivityListItemLayout>
);

ActivityListItem.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.node,
  state: PropTypes.string,
  moment: PropTypes.string,
  result: PropTypes.string,
  commits: PropTypes.array,
  openModal: PropTypes.func,
  link: PropTypes.node,
  linkCommits: PropTypes.bool,
  activity: PropTypes.object,
  overrideClass: PropTypes.string,
  activityContext: PropTypes.string,
  log: PropTypes.string
};

export default ActivityListItem;
