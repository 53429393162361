import React from "react";

const EmptyActivities = () => (
  <svg
    width="132"
    height="118"
    viewBox="0 0 132 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#empty-activities-clip0)">
      <path
        d="M67 118C101.24 118 129 113.52 129 108C129 102.48 101.24 98 67 98C32.76 98 5 102.48 5 108C5 113.52 32.76 118 67 118Z"
        fill="#C9D0E4"
      />
      <path
        d="M74 101C77.3137 101 80 98.3137 80 95C80 91.6863 77.3137 89 74 89C70.6863 89 68 91.6863 68 95C68 98.3137 70.6863 101 74 101Z"
        fill="white"
      />
      <path
        d="M126.19 3.79L127.59 5.19L125.49 7.29C125.14 7.64 125.14 8.17 125.49 8.51L125.58 8.6C125.93 8.95 126.46 8.95 126.81 8.6L128.91 6.5L130.31 7.9C130.57 8.16 131.1 7.99 131.1 7.55V3.44C131.1 3.18 130.93 3 130.66 3H126.55C126.1 3 125.93 3.44 126.19 3.79ZM121.2 3H117.44C117.18 3 117 3.18 117 3.44V7.2C117 7.55 117.44 7.81 117.79 7.55L119.01 6.32L122.6 9.91C122.95 10.26 123.13 10.7 123.13 11.13V16.12C123.13 16.65 123.48 17 124.01 17C124.54 17 124.89 16.65 124.89 16.12V10.34C124.89 9.9 124.71 9.46 124.36 9.12L120.25 5.01L121.47 3.79C121.81 3.44 121.55 3 121.2 3Z"
        fill="#4B6180"
      />
      <path
        d="M19.5 1V9"
        stroke="#C9D0E4"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.5 5.2C15.86 5.2 23.5 5.2 23.5 5.2"
        stroke="#C9D0E4"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M80.5001 93C80.5001 98 77.4892 100.759 73.7593 100.759C70.7255 100.759 68.1552 98.7551 67.3015 96"
        stroke="#4B6180"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M42.2531 66.5321C39.4501 56.0712 44.5156 45.2759 53.7972 40.4858L55 40.4858C47 59 65.0067 81.0298 72 94.5L46.3172 101.451C45.5308 101.661 44.8493 100.874 45.1789 100.129C46.4579 97.2574 48.0477 88.1579 46.6291 82.8634L42.2531 66.5321Z"
        fill="#5792FF"
      />
      <path
        d="M100.818 85.3433C98.2855 83.5718 92.511 76.6514 91.0262 71.11L86.6502 54.7787C86.1259 52.8218 85.3535 51.0001 84.3837 49.3194C84.0278 49.454 83.6578 49.5727 83.2829 49.6732C77.3575 51.2609 71.2705 47.7466 69.6828 41.8212C69.379 40.6873 69.2612 39.5526 69.3159 38.4403C67.4769 38.051 65.5754 37.9039 63.657 37.9866L63.02 35.6092C62.3315 33.0397 59.6673 31.5092 57.1069 32.1953C54.5374 32.8838 53.007 35.548 53.693 38.1083L54.3301 40.4858C45.0485 45.2759 39.983 56.0712 42.786 66.5321L47.1619 82.8634C48.5806 88.1579 46.9907 97.2574 45.7118 100.129C45.3821 100.874 46.0636 101.661 46.85 101.451L100.508 87.0731C101.303 86.8599 101.495 85.8187 100.818 85.3433Z"
        stroke="#4B6180"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M80.73 50.46C87.2083 50.46 92.46 45.2083 92.46 38.73C92.46 32.2517 87.2083 27 80.73 27C74.2517 27 69 32.2517 69 38.73C69 45.2083 74.2517 50.46 80.73 50.46Z"
        fill="white"
        stroke="#4B6180"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M84.5501 46.94C90.8738 46.94 96.0001 41.8136 96.0001 35.49C96.0001 29.1663 90.8738 24.04 84.5501 24.04C78.2264 24.04 73.1001 29.1663 73.1001 35.49C73.1001 41.8136 78.2264 46.94 84.5501 46.94Z"
        fill="#5792FF"
      />
      <path
        d="M80.73 50.46C87.2083 50.46 92.46 45.2083 92.46 38.73C92.46 32.2517 87.2083 27 80.73 27C74.2517 27 69 32.2517 69 38.73C69 45.2083 74.2517 50.46 80.73 50.46Z"
        stroke="#4B6180"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M84.94 38.92C84.94 42.9 83.46 45.1 80.87 45.1C78.58 45.1 77.04 42.96 77 39.09C77 35.16 78.69 33 81.07 33C83.54 33 84.94 35.19 84.94 38.92ZM78.59 39.1C78.59 42.14 79.53 43.87 80.97 43.87C82.59 43.87 83.36 41.98 83.36 38.99C83.36 36.11 82.62 34.22 80.98 34.22C79.6 34.22 78.59 35.91 78.59 39.1Z"
        fill="#4B6180"
      />
      <path
        d="M11.7 68.7C12.1 68.3 12.1 67.7 11.7 67.3L9.7 65.3C9.3 64.9 8.7 64.9 8.3 65.3C7.9 65.7 7.9 66.3 8.3 66.7L10.3 68.7C10.7 69.1 11.3 69.1 11.7 68.7ZM2.7 57H5V61.8C5 62.3 4.8 62.8 4.4 63.2L0.3 67.3C-0.1 67.7 -0.1 68.3 0.3 68.7C0.7 69.1 1.3 69.1 1.7 68.7L6.4 64C6.8 63.6 7 63.1 7 62.6V57H9.3C9.7 57 10 56.5 9.7 56.1L6.4 52.8C6.2 52.6 5.9 52.6 5.7 52.8L2.4 56.1C2 56.5 2.3 57 2.7 57Z"
        fill="#4B6180"
      />
      <path
        d="M122 82.9C121.9 82.4 122.2 81.9 122.7 81.8C123.1 81.7 123.5 81.5 123.8 81.3C124.2 81.1 124.8 81.2 125.1 81.6C125.5 82.1 125.3 82.8 124.8 83.1C124.3 83.4 123.8 83.6 123.3 83.7C122.7 83.8 122.1 83.4 122 82.9Z"
        fill="#C9D0E4"
      />
      <path
        d="M126.5 80.2C126.1 79.9 126 79.4 126.2 78.9C126.4 78.5 126.6 78.1 126.7 77.7C126.8 77.2 127.3 76.9 127.8 77C128.4 77.1 128.8 77.7 128.6 78.3C128.4 78.8 128.2 79.4 127.9 79.9C127.7 80.4 127 80.5 126.5 80.2Z"
        fill="#C9D0E4"
      />
      <path
        d="M126.6 71.9C126.2 72.2 126.1 72.7 126.3 73.2C126.5 73.6 126.7 74 126.8 74.4C126.9 74.9 127.4 75.2 127.9 75.1C128.5 75 128.9 74.4 128.7 73.8C128.5 73.3 128.3 72.7 128.1 72.2C127.8 71.7 127.1 71.5 126.6 71.9Z"
        fill="#C9D0E4"
      />
      <path
        d="M122.7 66.9L121.1 65.6C120.6 65.2 120 65.5 120 66.2V68.1C116.1 68.6 113 71.9 113 76C113 79.5 115.4 82.6 118.7 83.7C119.3 83.9 119.9 83.5 120 82.9C120.1 82.4 119.8 81.9 119.3 81.8C116.8 81 115 78.7 115 76C115 73 117.2 70.6 120 70.1V72.2C120 72.8 120.6 73.2 121.1 72.8L122.7 71.5L124.7 69.9C125.1 69.6 125.1 69 124.7 68.7L122.7 66.9Z"
        fill="#C9D0E4"
      />
    </g>
    <defs>
      <clipPath id="empty-activities-clip0">
        <rect width="131.09" height="118" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EmptyActivities;
