import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withRouter } from "react-router";
import { goToEnvironment } from "Libs/utils";

import EnvironmentListBar from "Components/EnvironmentListBar";

const ListLayout = styled.div`
  position: relative;
  .environment-line a {
    position: relative;
    margin: 0 -32px;
    padding: 0 32px;
    line-height: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    box-sizing: inherit;
    &:hover,
    &:focus {
      border-radius: 0;
      outline: none;
    }
    .list-bar-right {
      line-height: 32px;
      width: 76px;
      @media (min-width: 1280px) {
        width: 120px;
      }
    }
  }
  .info-button.website-link {
    top: 12px;
  }
  .environment-line .wings-dialog {
    top: 15px;
  }
  &.depth-0,
  &.depth-1 {
    .list-bar-left {
      line-height: 32px;
      .icon {
        min-width: 32px;
        width: 32px;
        height: 32px;
        &.root {
          background: ${props => props.theme.rootIcon};
        }
      }
    }
    .title {
      font-weight: 600;
    }
  }
  &.depth-1 {
    .list-bar-left .icon {
      min-width: 24px;
      width: 24px;
      height: 24px;
      @media (min-width: 1130px) {
        min-width: 32px;
        width: 32px;
        height: 32px;
      }
    }
  }
  &.depth-0,
  &.depth-1,
  &.depth-2 {
    .list-bar-left {
      .icon {
        background: ${props => props.theme.iconBg};
        svg path {
          fill: #ffffff;
        }
        &.inactive {
          background: #f0f2f5;
          svg path {
            fill: #98a0ab;
          }
        }
      }
    }
  }
`;

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      node,
      push,
      openedTooltip,
      closeTooltip,
      openTooltip,
      titleFilter
    } = this.props;
    return (
      <ListLayout
        className={`environment-row depth-${node.depth} ${
          node.isRoot ? "root" : "child"
        }${node.status == "inactive" ? " inactive" : " active"}`}
      >
        <EnvironmentListBar
          openTooltip={() => openTooltip(node.title)}
          closeTooltip={closeTooltip}
          isTooltipOpened={node.title === openedTooltip}
          node={node}
          organizationId={this.props.organizationId}
          className={`environment-list-row ${
            node.status === "inactive" ? "inactive" : ""
          }`}
          onClick={() =>
            goToEnvironment(
              push,
              this.props.organizationId,
              node.projectId,
              encodeURIComponent(node.environmentId)
            )
          }
          titleFilter={titleFilter}
          list={true}
        />
      </ListLayout>
    );
  }
}

List.propTypes = {
  organizationId: PropTypes.string.isRequired,
  node: PropTypes.object,
  push: PropTypes.func.isRequired,
  closeTooltip: PropTypes.func.isRequired,
  openTooltip: PropTypes.func.isRequired,
  openedTooltip: PropTypes.string,
  titleFilter: PropTypes.string
};

export default withRouter(List);
