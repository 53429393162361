import styled from "styled-components";

export default styled.button`
  background-color: transparent;
  border: 1px solid #c9d0e4;
  color: #4a495e;
  padding: 0 4px 0 16px !important;
  line-height: 30px;
  box-shadow: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */

  svg {
    width: 24px;
    min-width: 24px;
    height: auto;
    margin: 0;
  }
  &:hover {
    color: ${props => props.theme.links};
    svg path {
      fill: ${props => props.theme.links};
    }
  }
  &:focus {
    box-shadow: 0 0 2px #1664ff;
    border: 1px solid #1664ff;
    outline: none;
  }
  &.open {
    color: ${props => props.theme.links};
    svg {
      transform: rotate(180deg);
      path {
        fill: ${props => props.theme.links};
      }
    }
  }
  &.info-icon {
    height: 32px;
    width: 32px;
    padding: 4px;
  }
  &.with-label {
    label {
      color: #fff;
      opacity: 0.7;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 0;
      font-size: 10px;
    }
    span:not(.active) {
      line-height: 22px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .text {
      padding-right: 16px;
    }
  }
`;
