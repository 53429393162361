import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { Link } from "react-router";
import { injectIntl } from "react-intl";
import Highlighter from "react-highlight-words";
import momentjs from "moment";

import ReactTooltip from "react-tooltip";
import IconEnvBranch from "Icons/IconEnvBranch";
import IconEnvMaster from "Icons/IconEnvMaster";

import ListBar from "Components/ListBar";
import ListBarLeft from "Components/ListBarLeft";
import ListBarRight from "Components/ListBarRight";
import IconLink from "Icons/IconLink";
import WingsIcon from "Icons/WingsIcon";
import InfoDialog from "Components/InfoDialog";
import ActionDropdown from "Components/ActionDropdown";
import EnvironmentRoutes from "Containers/EnvironmentRoutes";
import InProgressStateIcon from "Components/icons/InProgressStateIcon";

const ListBarWrapper = styled.div`
  width: 100%;
  position: relative;
  > a {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 4px;
    margin: -4px;
    text-decoration: none;
    color: #1a192b;
    box-sizing: border-box;
  }
  .icon {
    &.inactive {
      svg path {
        fill: ${props => props.theme.inactive};
      }
    }
  }
  button {
    text-decoration: none;
    color: #1a192b;
    svg {
      opacity: 0.3;
    }
    &:hover svg,
    &.open svg {
      opacity: 1;
    }
  }
  .moment {
    text-align: right;
    font-size: 13px;
    color: #5f5e70;
  }
  .title {
    font-size: 14px;
    color: #242428;
    @media (min-width: 1130px) {
      font-size: 15px;
    }
  }
  > a:hover,
  > a:focus {
    background: ${props => props.theme.menuHover};
    border-radius: 4px;
    outline: none;
    .title {
      color: ${props => props.theme.iconBg};
    }
  }
  .blue {
    color: ${props => props.theme.links};
    background: transparent;
  }
  .website-link {
    position: absolute;
    right: 32px;
    top: 0;
    button {
      padding: 4px !important;
      background: transparent;
      border: none;
      width: 32px !important;
      height: 32px;
      box-shadow: none;
      border-radius: 4px;
      box-sizing: border-box;
      .svg-fill {
        fill: ${props => props.theme.links};
      }
      &:hover {
        background: ${props => props.theme.buttonBg} !important;
        .svg-fill {
          fill: ${props => props.theme.buttonText};
        }
      }
      &:focus {
        border: 1px solid ${props => props.theme.buttonBg};
        box-shadow: 0 0 2px ${props => props.theme.buttonBg};
        outline: none;
        height: 34px;
        width: 34px;
        margin: -1px;
        border-radius: 4px;
      }
      &.open,
      &:active {
        border: none !important;
        box-shadow: none !important;
        height: 32px;
        width: 32px;
        margin: 0;
      }
      svg {
        opacity: 1;
        margin: 0;
        width: 100%;
      }
    }
    &.open-wrapper {
      .tooltip {
        width: 80vw;
        border-radius: 2px;
        box-shadow: 0 6px 24px rgba(152, 160, 171, 0.4);
        margin-top: 4px;
        > section {
          max-height: 264px;
          border-radius: 2px;
          box-sizing: border-box;
          padding: 0;
        }
        .rcs-custom-scroll {
          .rcs-inner-container {
            padding: 8px;
            box-sizing: border-box;
          }
          .rcs-inner-handle {
            background: #4a495e;
            width: 4px;
            border-radius: 4px;
            opacity: 1;
          }
        }
        .no-scroll {
          padding: 8px;
        }
        .route-link {
          line-height: 32px;
          height: 32px;
          padding: 0 16px;
          margin: 0;
          box-sizing: border-box;
          &:hover {
            background: ${props => props.theme.menuHover};
            border-radius: 4px;
          }
          &:focus {
            outline: none;
            border: 1px solid #1664ff;
            border-radius: 4px;
            box-shadow: 0 0 2px #1664ff;
            margin: -1px;
          }
          &:active {
            background: transparent;
            border: none;
            box-shadow: none;
            margin: 0;
            span {
              color: ${props => props.theme.links};
            }
          }
        }
      }
      button {
        border-radius: 4px;
      }
      &:after {
        display: none;
      }
    }
    @media (min-width: 600px) {
      right: 48px;
      &.open-wrapper {
        .tooltip {
          width: 356px;
        }
      }
    }
    @media (min-width: 1280px) {
      right: 188px;
    }
  }
  .wings-dialog {
    position: absolute;
    right: 0;
    top: 4px;
    margin: 0;
    svg > path {
      fill: ${props => props.theme.links};
    }
    .dialog {
      right: 0;
      left: auto;
      margin-left: 0;
    }
    @media (min-width: 600px) {
      right: 8px;
    }
    @media (min-width: 1280px) {
      right: 132px;
    }
  }
`;
class EnvironmentListBar extends React.Component {
  render() {
    const {
      className,
      node,
      onClick,
      organizationId,
      isTooltipOpened,
      openTooltip,
      closeTooltip,
      intl,
      titleFilter,
      list
    } = this.props;

    const hasChildren = node.children.length > 0;

    const NodeTag = node.isMock ? "div" : Link;
    const nodeAttributes = node.isMock
      ? {}
      : {
          onClick: e => {
            e.preventDefault();
            onClick();
          },
          tabIndex: "0",
          href: `/${organizationId}/${node.projectId}/${node.environmentId}`,
          id: `environment-listbar-link-${node.environmentId}`
        };
    return (
      <ListBarWrapper className="environment-line">
        <NodeTag {...nodeAttributes}>
          <ListBar className={`environment-listbar ${className}`}>
            <ListBarLeft className="list-bar-left">
              <span
                className={`icon${node.isRoot ? " root" : ""}${
                  node.status === "inactive" ? " inactive" : ""
                }`}
              >
                {node.isRoot && (
                  <span data-tip data-for={`${node.environmentId}-root`}>
                    <span className="icon-wrapper">
                      <IconEnvMaster />
                    </span>
                    <ReactTooltip
                      id={`${node.environmentId}-root`}
                      place="bottom"
                      type="dark"
                      effect="solid"
                    >
                      {node.status === "inactive" && "Inactive "}Main branch
                    </ReactTooltip>
                  </span>
                )}
                {!node.isRoot &&
                  hasChildren && (
                    <span
                      className="parent"
                      data-tip
                      data-for={`${node.environmentId}-children`}
                    >
                      <span className="icon-wrapper">
                        <IconEnvBranch
                          color={this.props.theme && this.props.theme.iconBg}
                        />
                      </span>
                      <ReactTooltip
                        id={`${node.environmentId}-children`}
                        place="bottom"
                        type="dark"
                        effect="solid"
                      >
                        {node.status === "inactive"
                          ? "Inactive parent branch"
                          : "Parent branch"}
                      </ReactTooltip>
                    </span>
                  )}
                {!node.isRoot &&
                  !hasChildren &&
                  node.status === "inactive" && (
                    <React.Fragment>
                      <span
                        className="icon-wrapper"
                        data-tip
                        data-for={`${node.environmentId}-inactive`}
                      >
                        <IconEnvBranch
                          color={this.props.theme && this.props.theme.iconBg}
                        />
                      </span>
                      <ReactTooltip
                        id={`${node.environmentId}-inactive`}
                        place="bottom"
                        type="dark"
                        effect="solid"
                      >
                        Inactive child branch
                      </ReactTooltip>
                    </React.Fragment>
                  )}
                {!node.isRoot &&
                  !hasChildren &&
                  node.status !== "inactive" && (
                    <IconEnvBranch
                      color={this.props.theme && this.props.theme.iconBg}
                    />
                  )}
              </span>
              <span className="title">
                <Highlighter
                  highlightClassName="blue"
                  searchWords={[titleFilter]}
                  textToHighlight={node.title ? node.title : ""}
                  autoEscape={true}
                  activeIndex={-1}
                  caseSensitive={false}
                  className="environment-item"
                />
              </span>
            </ListBarLeft>
            <ListBarRight className="list-bar-right">
              {list && (
                <span
                  className="moment"
                  data-tip
                  data-for={`${node.environmentId}-moment`}
                >
                  {momentjs(node.created_at).fromNow()}
                  <ReactTooltip
                    id={`${node.environmentId}-moment`}
                    place="bottom"
                    type="dark"
                    effect="solid"
                  >
                    {momentjs(node.created_at).format("h:mm a on MMMM D, YYYY")}
                  </ReactTooltip>
                </span>
              )}
              {node.isDirty && <InProgressStateIcon />}
            </ListBarRight>
          </ListBar>
        </NodeTag>
        {node.status !== "inactive" && !node.isMock ? (
          <ActionDropdown
            id={`environment-list-${node.environmentId}-urls`}
            ariaLabel={intl.formatMessage(
              { id: "environments.links_tooltip" },
              {
                title: node.title
              }
            )}
            onOpen={() => node.status !== "inactive" && openTooltip()}
            onClose={() => closeTooltip()}
            isOpen={isTooltipOpened}
            className="website-link"
            withArrow={false}
            icon={<IconLink />}
          >
            <EnvironmentRoutes
              projectId={node.projectId}
              organizationId={organizationId}
              environmentId={node.environmentId}
            />
          </ActionDropdown>
        ) : (
          <div />
        )}
        {node.deployment_target !== "local" && (
          <InfoDialog
            icon={<WingsIcon />}
            title="Learn more"
            text="This branch deploys to the Enterprise Infrastructure"
            linkText="Learn more"
            to="https://ent.docs.platform.sh/"
            align="center"
            className="wings-dialog"
          />
        )}
      </ListBarWrapper>
    );
  }
}

EnvironmentListBar.propTypes = {
  className: PropTypes.string,
  organizationId: PropTypes.string,
  node: PropTypes.object,
  onClick: PropTypes.func,
  isTooltipOpened: PropTypes.bool,
  openTooltip: PropTypes.func,
  closeTooltip: PropTypes.func,
  intl: PropTypes.object,
  titleFilter: PropTypes.string,
  list: PropTypes.bool,
  theme: PropTypes.object
};

export default withTheme(injectIntl(EnvironmentListBar));
