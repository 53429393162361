import styled from "styled-components";

export default styled.div`
  &.info-button > button {
    width: 80px;
    span.icon {
      height: 16px;
      width: 16px;
      display: flex;
      align-items: center;
      svg {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
  &.environments-select {
    height: 56px;
    box-sizing: border-box;
    width: 100%;
    position: static;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    &.active {
      border-right: none;
    }
    &:hover,
    &.active {
      background: ${props => props.theme.navActive};
    }
    &:not(.active):hover {
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-width: 0 0 0 1px;
      margin-left: -1px;
    }
    &:focus-within {
      span.text {
        postion: relative;
        span.active {
          position: absolute;
          top: 35px;
          left: 12px;
          display: ${props =>
            props.theme.name === "contrast" ? "inline-block" : "none"};
          height: 4px;
          width: 4px;
          border-radius: 4px;
          background: #fff;
        }
      }
    }

    button {
      background: transparent;
      border: none;
      color: #fff;
      font-size: 16px;
      line-height: 56px;
      height: 56px;
      box-sizing: border-box;
      padding: 0 0 0 24px !important;
      width: 100%;
      span.chevron {
        min-width: 42px;
        height: 24px;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
      }
      svg {
        path {
          fill: #fff;
        }
      }
      &:hover,
      &:focus {
        outline: none;
        border: none;
        line-height: 40px;
        margin: 0;
        svg {
          path {
            fill: #fff;
          }
        }
      }
      &.open {
        color: #fff !important;
        svg {
          path {
            fill: #fff !important;
          }
        }
        &:hover,
        &:focus,
        &:active {
          outline: none;
          box-shadow: none;
        }
      }
    }
    .tooltip {
      left: 24px;
      right: auto;
      margin: 4px 0 0 0 !important;
      width: calc(100vw - 48px);
      border-radius: 2px;
      top: 100%;
      z-index: 3 !important;
      > section {
        border-radius: 2px;
        padding: 0;
      }
      .tooltip-content {
        clear: both;
      }
      .environments-menu {
        margin: 0;
      }
    }
    .environments-links {
      padding: 0;
      max-height: 332px;
      border-top: none;
      &.reduced-list {
        padding-bottom: 0;
      }
    }
    @media (min-width: 768px) {
      .tooltip {
        width: 100%;
        min-width: 300px;
        max-width: 90vw;
      }
      &.environments-select {
        position: relative;
        .tooltip {
          width: 300px;
          left: 0;
        }
      }
    }
  }
  &.inline-dropdown.open-wrapper {
      button, button:hover {
        color: ${props => props.theme.links};
        position: relative;
        z-index: 2;
        border-radius: 0;
        box-shadow: none;
        border-color: transparent;
        background: transparent;
        svg .chevron {
          fill: ${props => props.theme.links};
        }
      }
      .tooltip {
        z-index: 1;
        > div {
          padding-top: 60px;
        }
      }
    }
  }
  @media (min-width: 768px) {
    &.environments-select {
      width: 232px;
    }
  }
  @media (min-width: 960px) {
    position: relative;
  }
`;
