import React from "react";
import PropTypes from "prop-types";

import ActivityListItem from "../../ActivityListItem";
import ActivityTitle from "../../ActivityTitle";
import SystemIcon from "Icons/SystemIcon";

const EnvironmentVariableUpdateActivity = props => (
  <ActivityListItem
    icon={<SystemIcon color="#ffffff" size={20} />}
    title={
      <ActivityTitle
        name={props.activity.payload.user.display_name}
        primaryText="modified_environment_variable"
        primaryTitle={props.activity.payload.variable.name}
        organization={props.organizationId}
        project={props.activity.project}
        environment={
          props.activity.payload.environment
            ? props.activity.payload.environment.title
            : ""
        }
        environmentId={
          props.activity.payload.environment
            ? props.activity.payload.environment.id
            : ""
        }
        userId={props.activity.payload.user.id}
        activityContext={props.activityContext}
      />
    }
    state={props.activity.state}
    moment={props.activity.created_at}
    log={props.activity.log}
    {...props}
  />
);

EnvironmentVariableUpdateActivity.propTypes = {
  activity: PropTypes.object,
  organizationId: PropTypes.string,
  activityContext: PropTypes.string
};

export default EnvironmentVariableUpdateActivity;
