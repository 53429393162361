import React from "react";
import PropTypes from "prop-types";

const SyncIcon = ({ color = "#fff" }) => (
  <svg
    className="icon-sync"
    width="16px"
    height="19px"
    viewBox="0 0 16 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    alt="Sync icon"
  >
    <defs />
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-398.000000, -325.000000)"
        className="fill-target"
        fill={color}
      >
        <path d="M409.572327,329.528302 L405.044025,325 L405.044025,328.119497 C401.119497,328.622642 398,331.943396 398,336.069182 C398,340.194969 401.119497,343.515723 405.044025,344.018868 L405.044025,342.006289 C402.226415,341.503145 400.012579,339.08805 400.012579,336.069182 C400.012579,333.050314 402.226415,330.63522 405.044025,330.132075 L405.044025,334.056604 L409.572327,329.528302 L409.572327,329.528302 Z M414,335.062893 C413.798742,333.654088 413.295597,332.345912 412.389937,331.138365 L410.981132,332.54717 C411.484277,333.352201 411.886792,334.157233 411.987421,335.062893 L414,335.062893 L414,335.062893 Z M407.056604,342.006289 L407.056604,344.018868 C408.465409,343.81761 409.773585,343.314465 410.981132,342.408805 L409.572327,341 C408.767296,341.503145 407.962264,341.90566 407.056604,342.006289 L407.056604,342.006289 Z M410.981132,339.591195 L412.389937,341 C413.295597,339.792453 413.899371,338.484277 414,337.075472 L411.987421,337.075472 C411.886792,337.981132 411.484277,338.786164 410.981132,339.591195 L410.981132,339.591195 Z" />
      </g>
    </g>
  </svg>
);

SyncIcon.propTypes = {
  color: PropTypes.string
};

export default SyncIcon;
