import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Loading from "Components/Loading";

const TreeLayout = styled.div`
  height: ${props => props.height || "250px"};
  min-height: ${props => props.minHeight || "250px"};

  > div {
    height: 100%;
    overflow: hidden;

    > svg {
      overflow: visible;
    }
  }

  rect:focus {
    outline: none;
  }

  .node {
    fill: #ffffff;
  }

  .edgePath {
    stroke: #f0f0f0;
    fill: none;
    stroke-width: 2.5px;
    stroke-linecap: round;
  }
  .tooltip {
    opacity: 0;
    position: relative;
    overflow: visible;
    cursor: default;
  }
  .tooltip:after {
    content: "";
    position: absolute;
    top: -8px;
    border: 5px solid transparent;
    border-bottom-color: #38485e;
    left: 50%;
    margin-left: -5px;
    height: 0;
    width: 0;
  }

  .node:hover .tooltip {
    opacity: 1;
  }
`;

class TreeService extends React.Component {
  constructor(props) {
    super(props);

    this.getRef = this.getRef.bind(this);
    this.updateServices = this.updateServices.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateContainerDimensions = this.updateContainerDimensions.bind(this);
    this.getContainerRef = this.getContainerRef.bind(this);

    this.state = {};
  }

  componentDidMount() {
    this.updateServices(this.props.currentDeployment);
    document.getElementById("treeSvg").addEventListener(
      "treeSvgClick",
      e => {
        this.props.onClick(JSON.parse(e.detail));
      },
      true
    );
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    document
      .getElementById("treeSvg")
      .addEventListener("click", this.updateContainerDimensions);
  }

  componentWillReceiveProps(nextProps) {
    this.updateServices(nextProps.currentDeployment);
    this.updateWindowDimensions();
  }

  componentWillUnmount() {
    document
      .getElementById("treeSvg")
      .removeEventListener("click", this.updateContainerDimensions);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateContainerDimensions() {
    const svg = document
      .getElementById(this.props.id || "service-tree")
      .getElementsByTagName("svg")[0];

    svg.style.width = "100%";
    svg.style.height = this.containerRef.clientHeight;
  }

  updateWindowDimensions() {
    this.setState(() => {
      return {
        screenWidth: parseInt(window.innerWidth),
        screenHeight: parseInt(window.innerHeight)
      };
    });
  }

  updateServices(currentDeployment) {
    if (Object.keys(currentDeployment).length === 0) {
      return false;
    }

    const graphs = require("../libs/servicegraph/index");
    const d3 = require("d3");

    let g = this.generateGraph(currentDeployment.data);

    let graph = d3.select("#" + (this.props.id || "service-tree")),
      svg = graph.select("svg"),
      inner = svg.select("g");

    let render = new graphs.render();
    render(inner, g, true);

    // Center the graph
    let maxX = graph.node().offsetWidth,
      maxY = graph.node().offsetHeight;

    let padding = 5; // We need a minimum of padding to avoid clipping drop shadows.
    let initialScale = Math.min(
      (maxX - 2 * padding) / g.graph().width,
      (maxY - 2 * padding) / g.graph().height,
      1.5
    );

    // `overflow: visible` on <svg> elements should be supported by all
    // browsers now. In case it's not, enable this.
    // svg.attr("width", g.graph().width);
    // svg.attr("height", g.graph().height);

    let transform = d3.zoomIdentity
      .translate(
        (maxX - g.graph().width * initialScale) / 2,
        (maxY - g.graph().height * initialScale) / 2
      )
      .scale(initialScale);

    svg.style("transform-origin", "0 0");
    svg.style(
      "transform",
      "translate(" +
        transform.x +
        "px," +
        transform.y +
        "px) scale(" +
        transform.k +
        ")"
    );
  }

  isTweenService(service) {
    let [type] = service.type.split(":", 1);
    return type == "varnish";
  }

  generateGraph(deployment) {
    const graphlib = require("graphlib");

    const g = new graphlib.Graph();
    g.setGraph({});
    g.setDefaultNodeLabel(() => ({}));
    g.setDefaultEdgeLabel(() => ({}));

    const opts = g.graph();
    opts.ranker = "none";

    let hasTweenService = false;

    Object.entries(deployment.services).forEach(([name, service]) => {
      let [type] = service.type.split(":", 1);

      let rank = 0;

      if (this.isTweenService(service)) {
        // Varnish is a special case of services that sit between
        // routes and the app.
        rank = -2;
        hasTweenService = true;
      }

      g.setNode(name, { icon: type, rank: rank });

      Object.entries(service.relationships).forEach(([, relationship]) => {
        let [endpointName] = relationship.split(":", 1);
        g.setEdge(name, endpointName);
      });
    });

    g.setNode("router", { icon: "router", rank: hasTweenService ? -3 : -2 });

    Object.entries(deployment.webapps).forEach(([name, app]) => {
      let [type] = app.type.split(":", 1);

      g.setNode(name, { icon: type, rank: -1 });

      Object.entries(app.relationships).forEach(([, endpoint]) => {
        let [targetName] = endpoint.split(":", 1);
        const existingLabels = Object.keys(g._edgeLabels).map(item => {
          return item.replace(/\W/g, "");
        });
        const endpointRelationship = `${targetName}${name}`;
        // This prevents a label from being generated for dual relationships.
        // The causes undesired results otherwise.
        // See https://platformsh.atlassian.net/browse/PF-5573 for more info.
        // An example of this relationship would be: appOne->appTwo, appTwo->appOne.
        const relationshipHasLabel = existingLabels.indexOf(
          endpointRelationship
        );
        if (relationshipHasLabel > -1) {
          return;
        }

        g.setEdge(name, targetName);
      });
    });

    Object.entries(deployment.routes).forEach(([, route]) => {
      if (route.type != "upstream") {
        return;
      }

      let [targetName] = route.upstream.split(":", 1);
      g.setEdge("router", targetName);
    });

    g.nodes().forEach(n => {
      let node = g.node(n);
      node.width = 40;
      node.height = 40;
    });

    return g;
  }

  getRef(ref) {
    if (ref) {
      this.ref = ref;
    }
  }

  getContainerRef(ref) {
    if (ref) {
      this.containerRef = ref;
    }
  }

  render() {
    return (
      <TreeLayout
        id="treeSvg"
        innerRef={this.getContainerRef}
        minHeight={this.props.minHeight}
        height={this.props.height}
      >
        {this.props.hasCode &&
          this.state.loading && <Loading iconOnly={true} />}
        <div id={this.props.id || "service-tree"}>
          <svg>
            <defs>
              <filter id="shadow" width="200%" height="200%" x="-50%" y="-50%">
                <feDropShadow
                  dx="0"
                  dy="0.5"
                  stdDeviation="2"
                  floodColor="rgba(152, 160, 171, 0.4)"
                  floodOpacity="1"
                />
              </filter>
              <filter
                id="shadow-hover"
                width="200%"
                height="200%"
                x="-50%"
                y="-50%"
              >
                <feDropShadow
                  dx="0"
                  dy="3"
                  stdDeviation="6"
                  floodColor="rgba(75, 97, 128, 0.32)"
                  floodOpacity="2"
                />
              </filter>
            </defs>
            <g />
          </svg>
        </div>
      </TreeLayout>
    );
  }
}

TreeService.propTypes = {
  onClick: PropTypes.func,
  currentDeployment: PropTypes.object,
  height: PropTypes.string,
  id: PropTypes.string,
  minHeight: PropTypes.string,
  containerWidth: PropTypes.string,
  hasCode: PropTypes.bool
};

export default TreeService;
