import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import StatusLight from "./StatusLight";

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid grey;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;

  div + span {
    margin-left: 8px;
  }
`;

const AppStatus = ({ status }) => (
  <Layout>
    <StatusLight color="green" />
    <span>{status}</span>
  </Layout>
);

AppStatus.propTypes = {
  status: PropTypes.string
};

export default AppStatus;
