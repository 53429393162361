import PropTypes from "prop-types";
import { withRouter } from "react-router";

const Redirect = props => {
  props.router.push(props.to);
  return null;
};

Redirect.propTypes = {
  to: PropTypes.string
};

export default withRouter(Redirect);
