import React from "react";
import PropTypes from "prop-types";

const BackupIcon = ({ color = "#fff" }) => (
  <svg
    className="icon-backup"
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    alt="Backup icon"
  >
    <defs />
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-87.000000, -1364.000000)"
        className="fill-target"
        fill={color}
      >
        <g transform="translate(50.000000, 920.000000)">
          <g transform="translate(1.000000, 413.000000)">
            <g transform="translate(29.000000, 24.000000)">
              <path d="M24,17 L8,17 C7.45,17 7,17.45 7,18 L7,24 C7,24.55 7.45,25 8,25 L24,25 C24.55,25 25,24.55 25,24 L25,18 C25,17.45 24.55,17 24,17 L24,17 Z M11,23 C9.9,23 9,22.1 9,21 C9,19.9 9.9,19 11,19 C12.1,19 13,19.9 13,21 C13,22.1 12.1,23 11,23 L11,23 Z M24,7 L8,7 C7.45,7 7,7.45 7,8 L7,14 C7,14.55 7.45,15 8,15 L24,15 C24.55,15 25,14.55 25,14 L25,8 C25,7.45 24.55,7 24,7 L24,7 Z M11,13 C9.9,13 9,12.1 9,11 C9,9.9 9.9,9 11,9 C12.1,9 13,9.9 13,11 C13,12.1 12.1,13 11,13 L11,13 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

BackupIcon.propTypes = {
  color: PropTypes.string
};

export default BackupIcon;
