import React from "react";
import PropTypes from "prop-types";

import ActivityListItem from "./ActivityListItem";
import ActivityTitle from "./ActivityTitle";
import SystemIcon from "Icons/SystemIcon";

const EnvironmentUnknownActivity = props => (
  <ActivityListItem
    icon={<SystemIcon color="#ffffff" size={20} />}
    title={
      <ActivityTitle
        name={props.activity.payload.user.display_name}
        primaryText="unknown_activity"
        primaryTitle=""
        organization={"alice"}
        description={props.activity.data.description}
      />
    }
    state={props.activity.state}
    moment={props.activity.created_at}
    result={props.activity.result}
    commits={props.activity.commits}
    log={props.activity.log}
    {...props}
  />
);

EnvironmentUnknownActivity.propTypes = {
  activity: PropTypes.object
};

export default EnvironmentUnknownActivity;
