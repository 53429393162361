import React from "react";
import PropTypes from "prop-types";

const MergeIcon = ({ color = "#fff" }) => (
  <svg
    className="icon-merge"
    width="14px"
    height="18px"
    viewBox="0 0 14 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    alt="Merge icon"
  >
    <defs />
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-89.000000, -1127.000000)"
        className="fill-target"
        fill={color}
        fillRule="nonzero"
      >
        <g transform="translate(50.000000, 920.000000)">
          <g transform="translate(34.000000, 204.000000)">
            <path d="M17,20.41 L18.41,19 L15,15.59 L13.59,17 L17,20.41 Z M7.5,8 L11,8 L11,13.59 L5.59,19 L7,20.41 L13,14.41 L13,8 L16.5,8 L12,3.5 L7.5,8 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

MergeIcon.propTypes = {
  color: PropTypes.string
};

export default MergeIcon;
