import React from "react";
import PropTypes from "prop-types";

import ActivityListItem from "../ActivityListItem";
import ActivityTitle from "../ActivityTitle";
import MergeIcon from "../../icons/MergeIcon";

const EnvironmentMergePrActivity = props => (
  <ActivityListItem
    icon={<MergeIcon color="#ffffff" size={40} />}
    title={
      <ActivityTitle
        name={props.activity.payload.user.display_name}
        primaryText="merged_pull_request_from"
        primaryTitle={props.activity.payload.source.repository}
        organization={props.organizationId}
        project={props.activity.project}
        environment={
          props.activity.payload.environment
            ? props.activity.payload.environment.title
            : ""
        }
        environmentId={
          props.activity.payload.environment
            ? props.activity.payload.environment.id
            : ""
        }
        userId={props.activity.payload.user.id}
        activityContext={props.activityContext}
      />
    }
    state={props.activity.state}
    moment={props.activity.created_at}
    result={props.activity.result}
    commits={props.activity.commits}
    log={props.activity.log}
    {...props}
  />
);

EnvironmentMergePrActivity.propTypes = {
  activity: PropTypes.object,
  organizationId: PropTypes.string,
  activityContext: PropTypes.string
};

export default EnvironmentMergePrActivity;
