import React from "react";
import PropTypes from "prop-types";

const IconFilter = ({ color = "#4A495E" }) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="2" rx="1" fill={color} />
    <rect x="2" y="5" width="12" height="2" rx="1" fill={color} />
    <rect x="5" y="10" width="6" height="2" rx="1" fill={color} />
  </svg>
);

IconFilter.propTypes = {
  color: PropTypes.string
};

export default IconFilter;
