import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import { openRightDrawer } from "Reducers/app";
import { loadDeployment } from "Reducers/environment/deployment";

import Tree from "Components/TreeServiceManual";
import List from "Components/ServicesList";
import SearchInput from "Components/SearchInput";

import graphicViewIcon from "Images/graphic_view.svg";
import listViewIcon from "Images/list_view.svg";
import EmptyServices from "Components/illustrations/EmptyServices";

const Layout = styled.div`
  display: block;
  width: 100%;
  min-width: 200px;
  height: calc(100% - 54px);
`;

const TopBarLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 20px;
  margin-bottom: 10px;
`;

const ToolBar = styled.div`
  display: flex;
  img + img {
    margin-left: 10px;
  }

  img {
    cursor: pointer;
  }
`;

const EmptyTree = styled.div`
  animation: fadeIn 6s ease-in-out 0s forwards;
  text-align: center;
  .image {
    margin-bottom: 32px;
  }
  .message {
    color: #4a495e;
    margin-bottom: 8px;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

class ServicesDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.displayList = this.displayList.bind(this);
    this.displayTree = this.displayTree.bind(this);
    this.shouldLoadTree = this.shouldLoadTree.bind(this);

    this.state = {
      listMode: false,
      search: ""
    };
  }

  componentDidMount() {
    if (this.props.environment.id) {
      this.props.loadDeployment(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.environment &&
      nextProps.environment.id !== this.props.environment.id
    ) {
      this.props.loadDeployment(nextProps);
    }
  }

  shouldLoadTree() {
    if (
      this.props.environment &&
      this.props.environment.data &&
      this.props.environment.data.has_code
    ) {
      return true;
    }
    return false;
  }

  onSearchInputChange(e) {
    const search = e.target.value;

    this.setState(() => ({ search }));
  }

  displayList() {
    this.setState(() => ({ listMode: true }));
  }

  displayTree() {
    this.setState(() => ({ listMode: false }));
  }

  render() {
    const { currentDeployment, height, minHeight, display } = this.props;

    return (
      <Layout>
        {display === "environment" ? (
          <TopBarLayout>
            <SearchInput
              hidden={!this.state.listMode}
              aria-hidden={!this.state.listMode}
              onChange={this.onSearchInputChange}
              placeholder={this.props.intl.formatMessage({
                id: "environment_services.search_placeholder"
              })}
              iconSize={30}
            />
            <ToolBar>
              <div
                role="button"
                tabIndex="0"
                onClick={this.displayTree}
                onKeyUp={e => e.keyCode === 13 && this.displayTree()}
              >
                <img
                  src={graphicViewIcon}
                  width="20"
                  height="20"
                  alt="Graphics"
                />
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={this.displayList}
                onKeyUp={e => e.keyCode === 13 && this.displayList()}
              >
                <img src={listViewIcon} width="20" height="20" alt="List" />
              </div>
            </ToolBar>
          </TopBarLayout>
        ) : (
          ""
        )}

        {this.shouldLoadTree() ? (
          this.state.listMode ? (
            <List
              onClick={this.props.openDrawerFct}
              currentDeployment={currentDeployment}
              search={this.state.search}
            />
          ) : (
            <Tree
              onClick={this.props.openDrawerFct}
              currentDeployment={currentDeployment}
              containerHeight=""
              height={height}
              minHeight={minHeight}
              hasCode={
                this.props.environment &&
                this.props.environment.data &&
                this.props.environment.data.has_code
              }
            />
          )
        ) : (
          <EmptyTree id="empty-tree">
            <div className="image">
              <EmptyServices id="empty-services" />
            </div>
            <div className="message">
              There's no connected apps or services yet.
            </div>
          </EmptyTree>
        )}
      </Layout>
    );
  }
}

ServicesDisplay.propTypes = {
  openDrawerFct: PropTypes.func,
  loadDeployment: PropTypes.func,
  intl: PropTypes.object,
  currentDeployment: PropTypes.object,
  environment: PropTypes.object,
  projectId: PropTypes.string,
  environmentId: PropTypes.string,
  height: PropTypes.string,
  minHeight: PropTypes.string,
  display: PropTypes.string
};

const mapStateToProps = (state, props) => ({
  currentDeployment: state.deployment.getIn(
    [
      "data",
      props.organizationId,
      props.projectId,
      props.environmentId,
      "current"
    ],
    {}
  ),
  environment: state.environment.getIn(
    ["data", props.organizationId, props.projectId, props.environmentId],
    {}
  )
});

const mapDispatchToProps = dispatch => ({
  openDrawerFct: data => dispatch(openRightDrawer(data)),
  loadDeployment: ({ organizationId, projectId, environmentId }) =>
    dispatch(loadDeployment(organizationId, projectId, environmentId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ServicesDisplay));
