import React from "react";
import PropTypes from "prop-types";

const BranchIcon = ({ color = "#fff" }) => (
  <svg
    className="icon-branch"
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    alt="Branch icon"
  >
    <defs />
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-88.000000, -1128.000000)"
        className="fill-target"
        fill={color}
      >
        <g transform="translate(50.000000, 920.000000)">
          <g transform="translate(1.000000, 176.000000)">
            <g transform="translate(29.000000, 24.000000)">
              <path d="M17.6267071,8 L20.0591806,10.4324734 L17,13.491654 L18.508346,15 L21.5675266,11.9408194 L24,14.3732929 L24,8 L17.6267071,8 Z M14,8 L8,8 L8,14 L10.29,11.71 L15,16.41 L15,24 L17,24 L17,15.59 L11.71,10.29 L14,8 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

BranchIcon.propTypes = {
  color: PropTypes.string
};

export default BranchIcon;
