import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { injectIntl } from "react-intl";

import IconLayoutTree from "Components/icons/IconLayoutTree";
import IconLayoutList2 from "Components/icons/IconLayoutList2";
import ToggleWrapper from "Components/ToggleWrapper";

class ToggleTree extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ToggleWrapper background="#f5f7fa">
        <Link
          onClick={e => {
            e.preventDefault();
            this.props.toggleLayout(true);
            document.activeElement.blur();
          }}
          aria-label={this.props.intl.formatMessage({
            id: "environments.tree_layout"
          })}
          className={this.props.gridLayout ? "active" : ""}
          href="#"
          tabIndex="0"
        >
          <IconLayoutTree />
        </Link>
        <Link
          onClick={e => {
            e.preventDefault();
            this.props.toggleLayout(false);
            document.activeElement.blur();
          }}
          aria-label={this.props.intl.formatMessage({
            id: "environments.list_layout"
          })}
          className={this.props.gridLayout ? "" : "active"}
          href="#"
          tabIndex="0"
        >
          <IconLayoutList2 />
        </Link>
      </ToggleWrapper>
    );
  }
}

ToggleTree.propTypes = {
  toggleLayout: PropTypes.func,
  gridLayout: PropTypes.bool,
  intl: PropTypes.object
};

export default injectIntl(ToggleTree);
