import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import AppStatus from "./AppStatus";

import pythonLogo from "Images/python.svg";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 2vw 10px 10px;
  align-items: center;
  background-color: white;
  margin-bottom: 5px;
`;

const RowLeftLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img + span {
    margin-left: 20px;
  }
`;

const Logo = styled.img`
  width: 20px;
  height: 20px;
`;

const Name = styled.span`
  text-transform: capitalize;
  font-weight: bold;
`;

const ServicesList = ({ onClick, currentDeployment, search }) => {
  const nodes = {
    ...currentDeployment.webapps,
    ...currentDeployment.services,
    ...currentDeployment.workers
  };

  return (
    <Layout>
      {Object.keys(nodes)
        .filter(
          k =>
            search && search.length
              ? k.toUpperCase().startsWith(search.toUpperCase())
              : true
        )
        .map(k => {
          const node = nodes[k];
          node.name = k;

          return (
            <Row key={k} onClick={() => onClick(node)}>
              <RowLeftLayout>
                <Logo src={pythonLogo} alt="Logo" />
                <Name>{k}</Name>
              </RowLeftLayout>
              <AppStatus status="critical" />
            </Row>
          );
        })}
    </Layout>
  );
};

ServicesList.propTypes = {
  onClick: PropTypes.func,
  currentDeployment: PropTypes.object,
  search: PropTypes.string
};

export default ServicesList;
