import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { goToEnvironment } from "Libs/utils";

import HorizontalLine from "./HorizontalLine";
import VerticalLine from "./VerticalLine";

import EnvironmentListBar from "Components/EnvironmentListBar";

const Layout = styled.li`
  list-style: none;
  span.icon svg {
    max-height: 100%;
    max-width: 100%;
  }
`;

const NodeLayout = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: 10px;
  margin-bottom: 24px;

  img + span {
    margin-left: 10px;
  }
  &.depth-0 {
    margin-bottom: 16px;
  }
  &.depth-1 {
    margin-bottom: 20px;
  }
  &.depth-0,
  &.depth-1 {
    .list-bar-left {
      line-height: 32px;
      .icon {
        min-width: 32px;
        width: 32px;
        height: 32px;
        &.root {
          background: ${props => props.theme.rootIcon} !important;
        }
      }
    }
    .title {
      font-weight: 600;
    }
  }
  &.depth-1 {
    .list-bar-left .icon {
      min-width: 24px;
      width: 24px;
      height: 24px;
      @media (min-width: 1130px) {
        min-width: 32px;
        width: 32px;
        height: 32px;
      }
    }
  }
  &.has-children {
    .list-bar-left {
      .icon {
        background: ${props => props.theme.iconBg};
        svg path {
          fill: #ffffff;
        }
        &.inactive {
          background: #f0f2f5 !important;
          svg path {
            fill: ${props => props.theme.inactive};
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    li.root li.environment-row {
      padding-left: 32px !important;
    }
  }
`;

class Node extends React.Component {
  render() {
    const {
      nodes,
      push,
      openedTooltip,
      closeTooltip,
      openTooltip,
      compactView
    } = this.props;

    if (!nodes || !nodes.length) {
      return false;
    }

    return nodes
      .sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
        if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
        return 0;
      })
      .map(node => {
        const hasChildren = node.children.length > 0;
        const search = (needle, haystack, found = []) => {
          Object.keys(haystack).forEach(key => {
            if (key === needle) {
              found.push(haystack[key]);
              return found;
            }
            if (typeof haystack[key] === "object") {
              search(needle, haystack[key], found);
            }
          });
          return found;
        };
        let childStatuses = search("status", node.children);
        let activeChildren = childStatuses.find(status => {
          return status === "active";
        });
        let inactiveChildren = childStatuses.find(status => {
          return status !== "active";
        });
        let rowPadding = compactView ? "0 0 0 41px" : "0 0 0 48px";
        let verticalSize = "calc(100% - 40px)";
        let verticalLineLeft = compactView ? "53px" : "60px";
        let verticalLineTop = "34px";
        let horizontalLineTop = "22px";
        let horizontalLineLeft = compactView ? "13px" : "12px";
        let horizontalLineWidth = compactView ? "28px" : "38px";
        if (node.isRoot) {
          rowPadding = "0";
        }
        if (node.isRoot) {
          verticalLineLeft = compactView ? "12px" : "16px";
          verticalSize = compactView
            ? "calc(100% - 26px)"
            : "calc(100% - 34px)";
          verticalLineTop = compactView ? "34px" : "42px";
        } else {
          if (node.depth < 2) {
            verticalLineLeft = compactView ? "54px" : "64px";
            verticalLineTop = compactView ? "34px" : "42px";
            verticalSize = compactView
              ? "calc(100% - 38px)"
              : "calc(100% - 48px)";
            horizontalLineTop = compactView ? "22px" : "26px";
          }
          if (node.depth < 3) {
            horizontalLineLeft = compactView ? "13px" : "16px";
          }
        }
        if (node.children.length === 1) {
          if (node.depth < 2) {
            verticalSize = compactView ? "26px" : "34px";
          } else {
            verticalSize = compactView ? "26px" : "42px;";
          }
        }
        return (
          <Layout
            className={`environment-row ${node.isRoot ? "root" : "child"}${
              node.status == "inactive" ? " inactive" : " active"
            }${activeChildren ? " active-children" : ""}${
              inactiveChildren ? " inactive-children" : ""
            } tree-depth-${node.depth}`}
            key={node.title}
            style={{ position: "relative", padding: rowPadding }}
          >
            {!node.isRoot ? (
              <HorizontalLine
                size={horizontalLineWidth}
                top={horizontalLineTop}
                left={horizontalLineLeft}
                className="horizontal-line"
              />
            ) : (
              false
            )}
            <NodeLayout
              className={`environment-row-item depth-${node.depth}${
                hasChildren ? " has-children" : ""
              }`}
            >
              <EnvironmentListBar
                openTooltip={() =>
                  openedTooltip ? openTooltip("") : openTooltip(node.title)
                }
                closeTooltip={closeTooltip}
                isTooltipOpened={node.title === openedTooltip}
                node={node}
                organizationId={this.props.organizationId}
                className={`environment-tree-row ${
                  node.status === "inactive" ? "inactive" : "active"
                }`}
                onClick={() =>
                  goToEnvironment(
                    push,
                    this.props.organizationId,
                    node.projectId,
                    encodeURIComponent(node.environmentId)
                  )
                }
              />
            </NodeLayout>
            {node.children && (
              <ol>
                {node.children.length ? (
                  <div>
                    <VerticalLine
                      size={verticalSize}
                      left={verticalLineLeft}
                      top={verticalLineTop}
                      className="vertical-line"
                    />
                  </div>
                ) : (
                  false
                )}
                <Node
                  openTooltip={openTooltip}
                  closeTooltip={closeTooltip}
                  openedTooltip={openedTooltip}
                  nodes={node.children}
                  organizationId={this.props.organizationId}
                  push={push}
                  compactView={compactView}
                />
              </ol>
            )}
          </Layout>
        );
      });
  }
}

Node.propTypes = {
  isRoot: PropTypes.bool,
  openedTooltip: PropTypes.string,
  nodeImageSize: PropTypes.number,
  nodes: PropTypes.array,
  organizationId: PropTypes.string,
  push: PropTypes.func.isRequired,
  closeTooltip: PropTypes.func.isRequired,
  openTooltip: PropTypes.func.isRequired,
  compactView: PropTypes.bool
};

export default Node;
