const util = require("../lib/util");

module.exports = addHtmlLabel;

function addHtmlLabel(root, node) {
  let fo = root
    .attr("class", "tooltip-wrapper")
    .append("foreignObject")
    .attr("width", "100000");

  let div = fo.append("xhtml:div");
  div.attr("xmlns", "http://www.w3.org/1999/xhtml");

  let label = node.label;
  switch (typeof label) {
    case "function":
      div.insert(label);
      break;
    case "object":
      // Currently we assume this is a DOM object.
      div.insert(function() {
        return label;
      });
      break;
    default:
      div.html(label);
  }

  util.applyStyle(div, node.labelStyle);
  div.style("display", "inline-block");
  // Fix for firefox
  div.style("white-space", "nowrap");
  // Style the tooltips to match rest of UI
  div.style("font-weight", "400");
  div.style("padding", "5px 8px 7px");
  div.style("background", "#4A4B61");
  div.style("color", "#ffffff");
  div.style("border-radius", "3px");

  let client = div.node().getBoundingClientRect();
  fo.attr("width", client.width).attr("height", client.height);

  return fo;
}
