import React from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";
import { injectIntl } from "react-intl";
import Ansi from "ansi-to-react";

import logger from "Libs/logger";

import Loading from "Components/Loading";
import ActivityTypesDropdown from "Components/ActivityTypesDropdown";
import ModalWrapper from "Components/ModalWrapper";
import Button from "Components/Button";
import SystemIcon from "Icons/SystemIcon";
import PushIcon from "Icons/PushIcon";
import SyncIcon from "Icons/SyncIcon";
import BackupIcon from "Icons/BackupIcon";
import MergeIcon from "Icons/MergeIcon";
import BranchIcon from "Icons/BranchIcon";
import EmptyActivities from "Components/illustrations/EmptyActivities";
import Heading2 from "Components/styleguide/Heading2";

const ActivityListLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding-top: 0;
`;

const ActivityFilterLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  height: 32px;
  position: absolute;
  right: 0;
  top: -58px;
  &:first-letter {
    text-tranform: uppercase;
    display: inline-block;
  }
`;

const ActivityListScroll = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  background: #fff;
  margin: 0 -32px;
  border-top: 1px solid #d8e2f0;
`;

const ShowMoreWrapper = styled.div`
  text-align: center;
  padding-top: 14px;
  border-top: 1px solid #d8e2f0;
`;

const EmptyText = styled.div`
  text-align: center;
  margin: 68px 0;
  width: 100%;
  h2 {
    margin-bottom: 16px;
  }
  p {
    margin-bottom: 40px;
    color: #5f5e70;
    font-size: 15px;
  }
`;

const ImageWrapper = styled.div`
  margin: 0 auto 24px;
  width: 156px;
  svg {
    max-width: 100%;
    height: auto;
  }
`;

// The import function use require because it is not yet implementde in browser
// http://caniuse.com/#feat=es6-module
const getActivityComponent = type => {
  let module;
  try {
    module = require("Components/activities/" +
      type
        .replace(/\./g, "/")
        .replace(
          /\/([A-z_\-0-9]+)$/,
          x => "/" + x.charAt(1).toUpperCase() + x.slice(2)
        )).default;
  } catch (err) {
    logger(err, type);
    module = require("Components/activities/Unknown").default;
  }

  return module;
};

const sortActivities = (activity1, activity2) =>
  new Date(activity2.created_at).getTime() -
  new Date(activity1.created_at).getTime();

class ActivityList extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.getActivityIcon = this.getActivityIcon.bind(this);
    this.updateActivity = this.updateActivity.bind(this);
    this.state = {
      modalIcon: <SystemIcon color="#ffffff" size={40} />
    };
  }

  componentDidMount() {
    this.updateActivity();
    this.refreshTimer = setInterval(() => {
      this.updateActivity();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimer);
  }

  updateActivity() {
    this.setState({
      refresh: new Date()
    });
  }

  openModal(modalContent) {
    this.setState({
      isModalOpen: true,
      modalContent
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  onFilterChange(value) {
    this.props.loadActivitiesOfType(value);
    this.setState({
      filterValue: value
    });
  }

  loadMore() {
    this.props.loadMore(this.state.filterValue || {});
  }

  getActivityIcon(activityType) {
    let activityIcon = <SystemIcon color="#ffffff" size={40} />;
    switch (activityType) {
      case "environment.backup":
      case "environment.restore": {
        activityIcon = <BackupIcon color="#ffffff" size={40} />;
        break;
      }
      case "environment.push":
      case "project.create": {
        activityIcon = <PushIcon color="#ffffff" size={40} />;
        break;
      }
      case "environment.branch": {
        activityIcon = <BranchIcon color="#ffffff" size={40} />;
        break;
      }
      case "environment.merge": {
        activityIcon = <MergeIcon color="#ffffff" size={40} />;
        break;
      }
      case "environment.synchronize": {
        activityIcon = <SyncIcon color="#ffffff" size={40} />;
        break;
      }
      default: {
        activityIcon = <SystemIcon color="#ffffff" size={40} />;
        break;
      }
    }
    this.setState({
      modalIcon: activityIcon
    });
  }

  render() {
    const activities =
      this.props.activities && this.props.activities.valueSeq();
    const { intl, filter = true, activityContext, organizationId } = this.props;
    const { modalContent = {}, isLoading } = this.state;
    return (
      <ActivityListLayout>
        {filter && (
          <ActivityFilterLayout>
            <ActivityTypesDropdown
              value={this.state.filterValue}
              onChange={this.onFilterChange}
            />
          </ActivityFilterLayout>
        )}
        <ActivityListScroll>
          {activities && activities.size === 0 ? (
            <EmptyText className="empty-text">
              <ImageWrapper>
                <EmptyActivities />
              </ImageWrapper>
              <Heading2>No activity</Heading2>
              <p>Update your filters to show other activity types.</p>
            </EmptyText>
          ) : (
            <InfiniteScroll
              loadMore={this.loadMore}
              hasMore={this.props.hasMore}
              initialLoad={false}
              loader={
                this.props.activityIsLoading ? (
                  <Loading iconOnly={true} />
                ) : null
              }
              useWindow={false}
            >
              {activities &&
                activities.sort(sortActivities).map(activity => {
                  const Activity = getActivityComponent(activity.type);
                  if (activity.type !== "environment.backup") {
                    return (
                      <Activity
                        key={activity.id}
                        openModal={this.openModal.bind(this, activity.log)}
                        activity={activity}
                        environmentPublicUrl={this.props.environmentPublicUrl}
                        canEditProject={this.props.canEditProject}
                        activityContext={activityContext}
                        organizationId={organizationId}
                      />
                    );
                  }
                  return;
                })}
            </InfiniteScroll>
          )}
          <ShowMoreWrapper>
            {this.props.hasMore && (
              <Button
                onClick={() => {
                  this.loadMore();
                  document.activeElement.blur();
                }}
                className="link-style"
              >
                Show more
              </Button>
            )}
          </ShowMoreWrapper>
        </ActivityListScroll>
        <ModalWrapper
          isOpen={this.state.isModalOpen}
          closeModal={this.closeModal}
          modalClass="modal-build-log modal-large"
          title={intl.formatMessage({ id: "build_log" })}
          copyText={modalContent ? modalContent : "No logs"}
        >
          <pre id="log-scroll">
            {isLoading ? (
              <Loading />
            ) : modalContent === "" ? (
              "No logs"
            ) : (
              <Ansi>{modalContent || ""}</Ansi>
            )}
          </pre>
        </ModalWrapper>
      </ActivityListLayout>
    );
  }
}

ActivityList.propTypes = {
  activities: PropTypes.oneOfType([
    ImmutablePropTypes.map,
    ImmutablePropTypes.list
  ]),
  environmentPublicUrl: PropTypes.string,
  canEditProject: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadMore: PropTypes.func,
  loadActivitiesOfType: PropTypes.func,
  intl: PropTypes.object,
  filter: PropTypes.bool,
  activityContext: PropTypes.string,
  organizationId: PropTypes.string,
  hasMore: PropTypes.bool,
  activityIsLoading: PropTypes.bool
};

export default injectIntl(ActivityList);
