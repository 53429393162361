import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";

import ActivityListItem from "../ActivityListItem";
import ActivityTitle from "../ActivityTitle";
import SyncIcon from "../../icons/SyncIcon";

const getPrimaryText = (state, result) => {
  if (state === "complete" && result !== "failure") {
    return "synced";
  } else if (state !== "complete" || result === "failure") {
    return "initiated_sync";
  }

  return "";
};

const getPrimaryTitle = (synchronize_code, synchronize_data) => {
  return `${synchronize_code ? "code" : ""} ${
    synchronize_code && synchronize_data ? "&" : ""
  } ${synchronize_data ? "data" : ""}`;
};

const EnvironmentSyncronizeActivity = props => (
  <ActivityListItem
    icon={<SyncIcon color="#ffffff" size={40} />}
    title={
      <ActivityTitle
        name={props.activity.payload.user.display_name}
        primaryText={getPrimaryText(
          props.activity.state,
          props.activity.result
        )}
        primaryTitleCustom={
          <span>
            <Link
              to={`/${props.organizationId}/${
                props.activity.project
              }/${encodeURIComponent(props.activity.payload.outcome.id)}`}
            >
              <strong>{props.activity.payload.outcome.title}</strong>
            </Link>
            {"'s "}
            {getPrimaryTitle(
              props.activity.payload.synchronize_code,
              props.activity.payload.synchronize_data
            )}
          </span>
        }
        secondaryText="with"
        secondaryTitle={
          <Link
            to={`/${props.organizationId}/${
              props.activity.project
            }/${encodeURIComponent(props.activity.payload.environment.id)}`}
          >
            {props.activity.payload.environment.title}
          </Link>
        }
        organization={props.organizationId}
        project={props.activity.project}
        environment={
          props.activity.payload.environment
            ? props.activity.payload.environment.title
            : ""
        }
        environmentId={
          props.activity.payload.environment
            ? props.activity.payload.environment.id
            : ""
        }
        userId={props.activity.payload.user.id}
        activityContext={props.activityContext}
      />
    }
    state={props.activity.state}
    moment={props.activity.created_at}
    result={props.activity.result}
    commits={props.activity.commits}
    log={props.activity.log}
    {...props}
  />
);

EnvironmentSyncronizeActivity.propTypes = {
  activity: PropTypes.object,
  organizationId: PropTypes.string,
  activityContext: PropTypes.string
};

export default EnvironmentSyncronizeActivity;
