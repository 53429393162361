import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { Link } from "react-router";

import CommitListItem from "./CommitListItem";

const CommitListLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 8px 104px;
`;

const HideLayout = styled.div`
  list-style: none;
  margin: -8px 0 8px;
  text-decoration: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
  a {
    cursor: pointer;
    font-size: 14px;
    color: ${props => props.theme.links};
    &:focus {
      margin-top: -1px;
      margin-bottom: -1px;
    }
  }
`;

const MAX_COMMIT_DISPLAY = 0;

class CommitList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.open = this.open.bind(this);
  }

  open(e) {
    e.stopPropagation();
    this.setState(prevState => ({ open: !prevState.open }));
  }

  filterCommits(maxCommitNumber, commits, { open }) {
    if (open) {
      return commits;
    }

    return commits.slice(0, maxCommitNumber || MAX_COMMIT_DISPLAY);
  }

  render() {
    const { commits, intl } = this.props;
    if (!commits) {
      return false;
    }

    return (
      <CommitListLayout result={this.props.result}>
        {this.props.isOpen &&
          this.props.commits.map(commit => (
            <CommitListItem
              key={commit.sha}
              sha={commit.sha}
              message={commit.message}
              name={commit.author && commit.author.name}
              date={commit.author && commit.author.date}
            />
          ))}
        {this.props.isOpen && (
          <HideLayout>
            <Link
              className="text-link"
              onClick={e => {
                e.preventDefault();
                this.props.close();
              }}
              tabIndex="0"
              href=""
            >
              {intl.formatMessage({ id: "hide_commits" })}
            </Link>
          </HideLayout>
        )}
      </CommitListLayout>
    );
  }
}

CommitList.propTypes = {
  commits: PropTypes.array,
  result: PropTypes.string,
  maxCommitNumber: PropTypes.number,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  intl: PropTypes.object
};

export default injectIntl(CommitList);
