const dagre = require("dagre"),
  _ = require("lodash");

module.exports = position;

function position(g, options) {
  let layering = dagre.util.buildLayerMatrix(g);

  const gridStepX = options.gridStepX,
    gridStepY = options.gridStepY;

  // Assign y positions on the grid.
  _.forEach(layering, function(layer, yi) {
    _.forEach(layer, function(n, xi) {
      let node = g.node(n);
      node.y = yi * gridStepY;

      // Initial x position.
      node.x = xi * gridStepX;
    });
  });

  // Assign x positions.
  _.forEach(layering, function(layer, i) {
    if (i == 0) {
      return;
    }

    positionPass(g, layer, gridStepX, function(n) {
      return _.map(g.inEdges(n), function(p) {
        return p.v;
      });
    });
  });

  // Assign x positions.
  _.forEach(_.reverse(layering), function(layer, i) {
    if (i == 0) {
      return;
    }

    positionPass(g, layer, gridStepX, function(n) {
      return _.map(
        _.filter(g.outEdges(n), function(e) {
          return !isDummyNode(e.w);
        }),
        function(p) {
          return p.w;
        }
      );
    });
  });
}

function isDummyNode(n) {
  return n.startsWith("_d");
}

function positionPass(g, layer, gridStep, precedessorGetter) {
  layer = _.map(layer, function(n, i) {
    let predecessors = precedessorGetter(n);
    let hasDummyPredecessor = false;
    _.forEach(predecessors, function(p) {
      if (isDummyNode(p)) {
        hasDummyPredecessor = true;
      }
    });

    return {
      id: n,
      index: i,
      predecessors: predecessors,
      dummy: hasDummyPredecessor,
      x: null
    };
  });

  // Sort nodes in the layer so that:
  // - Dummy nodes are processed first,
  // - Nodes with the least incoming edges are processed first.
  let nodeOrder = _.sortBy(layer, [
    function(n) {
      return n.dummy ? -1 : 1;
    },
    function(n) {
      return n.predecessors.length;
    }
  ]);

  _.forEach(nodeOrder, function(n, i) {
    let node = g.node(n.id);

    // console.log('positioning node', n, node);

    // Optimal position as the median of the position of the predecessors.
    // In case of an even number of predecessors, use the left of the two
    // center predecessors. In case there are no precedessors, place in the
    // left most possible position.
    let optimalX;
    if (n.predecessors.length > 0) {
      let positions = _.map(n.predecessors, function(p) {
        return g.node(p).x;
      });
      positions.sort(function(a, b) {
        a - b;
      });

      // console.log('predecessor positions', positions);

      optimalX = positions[Math.floor((positions.length - 1) / 2)];
    } else {
      optimalX = -Infinity;
    }

    // console.log('optimal position', optimalX);

    // Now compute the range of valid positions.
    if (i != 0) {
      let minX = -Infinity,
        maxX = Infinity;

      let paddingLeft = 0;
      for (let idx = n.index - 1; idx >= 0; idx--) {
        // console.log('left', layer[idx]);
        paddingLeft += gridStep;
        if (layer[idx].x !== null) {
          minX = layer[idx].x + paddingLeft;
          break;
        }
      }

      let paddingRight = 0;
      for (let idx = n.index + 1; idx < layer.length; idx++) {
        // console.log('right', layer[idx]);
        paddingRight += gridStep;
        if (layer[idx].x !== null) {
          maxX = layer[idx].x - paddingRight;
          break;
        }
      }

      // console.log('range', minX, maxX);

      if (optimalX < minX) {
        optimalX = minX;
      } else if (optimalX > maxX) {
        optimalX = maxX;
      }
    }

    if (optimalX == -Infinity) {
      optimalX = 0;
    }

    n.x = optimalX;
    node.x = optimalX;

    // console.log('final', i, node.x, node.y);
  });
}
