import styled from "styled-components";

const Square = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size || 20}px;
  height: ${props => props.size || 20}px;
  min-width: ${props => props.size || 20}px;
  background-color: ${props => props.color || props.theme.iconBg};
`;

export default Square;
