import React from "react";
import PropTypes from "prop-types";

import ActivityListItem from "../../ActivityListItem";
import ActivityTitle from "../../ActivityTitle";
import SystemIcon from "Icons/SystemIcon";

const ProjectDomainUpdateActivity = props => (
  <ActivityListItem
    icon={<SystemIcon color="#ffffff" size={20} />}
    title={
      <ActivityTitle
        name={props.activity.payload.user.display_name}
        primaryText="updated_domain"
        primaryTitle={props.activity.payload.domain.name}
      />
    }
    state={props.activity.state}
    moment={props.activity.created_at}
    result={props.activity.result}
    log={props.activity.log}
    {...props}
  />
);

ProjectDomainUpdateActivity.propTypes = {
  activity: PropTypes.object
};

export default ProjectDomainUpdateActivity;
