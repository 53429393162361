import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Circle = styled.div`
  border-radius: 50%;
  background-color: ${props => props.theme.status};
  width: 10px;
  height: 10px;
`;

const StatusLight = ({ color }) => <Circle color={color} />;

StatusLight.propTypes = {
  color: PropTypes.string
};

export default StatusLight;
