import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Link } from "react-router";

import UserAvatar from "react-user-avatar";
import styled from "styled-components";
import Client from "Libs/platform";
import logger from "Libs/logger";
import ReactTooltip from "react-tooltip";

const ActivityInfo = styled.span`
  display: flex;
  align-items: center;
  .avatar {
    margin-right: 20px;
  }
  .UserAvatar {
    font-size: 12px;
    font-weight: 600;
    overflow: hidden;
    min-width: 24px;
    text-transform: uppercase;
    color: #1a192b;
  }
  .organization {
    opacity: 0.5;
  }
  .title-text {
    display: flex;
    align-items: center;
    max-width: 100%;
    strong a {
      font-weight: 700;
    }
  }
  .info {
    display: inline-block;
    min-height: 24px;
  }
  .environment {
    color: #5f5e70;
    font-size: 12px;
  }
  button.link-style {
    line-height: 30px;
    font-size: 16px;
    padding: 0;
  }
  .activity-button-wrapper {
    margin-left: 5px;
  }
  user,
  environment {
    font-weight: 700;
  }
  &.project-activity {
    .title-text {
      display: block;
    }
  }
`;

class ActivityTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      picture: ""
    };
  }

  componentDidMount() {
    if (this.props.userId) {
      Client.getAccount(this.props.userId)
        .then(account => {
          this.setState({ picture: account.picture });
        })
        .catch(error => {
          logger(error);
        });
    }
  }

  render() {
    const {
      name,
      primaryTitle,
      primaryTitleCustom,
      secondaryTitle,
      primaryText,
      secondaryText,
      description,
      intl,
      link,
      userId,
      activityContext,
      environment,
      environmentId,
      organization,
      project
    } = this.props;

    const clipName = name.split(/[\s.]+/);
    return (
      <ActivityInfo
        className={
          activityContext === "project"
            ? "project-activity"
            : "environment-activity"
        }
      >
        <div
          className="avatar"
          data-tip
          data-for={`user-name-${userId ? userId : name.replace(" ", "-")}`}
        >
          <UserAvatar
            size="24"
            name={clipName.length > 2 ? clipName.slice(0, 2).join(" ") : name}
            src={
              this.state.picture
                ? this.state.picture.includes("user-default.png")
                  ? ""
                  : this.state.picture
                : ""
            }
            colors={[
              "#ffcdd2",
              "#e1bee7",
              "#c5cae9",
              "#b3e5fc",
              "#b2dfdb",
              "#dcedc8",
              "#fff9c4",
              "#ffe0b2",
              "#d7ccc8",
              "#f8bbd0",
              "#d1c4e9",
              "#bbdefb",
              "#b2ebf2",
              "#c8e6c9",
              "#f0f4c3",
              "#ffecd3",
              "#ffccbc"
            ]}
          />
          <ReactTooltip
            id={`user-name-${userId ? userId : name.replace(" ", "-")}`}
            place="bottom"
            effect="solid"
          >
            {name ? name : ""}
          </ReactTooltip>
        </div>
        <div className="title-text">
          {description ? (
            <div
              className="info"
              dangerouslySetInnerHTML={{ __html: description }} // eslint-disable-line
            />
          ) : (
            <div className="info">
              <strong>{`${name} `}</strong>
              {`${intl.formatMessage({ id: primaryText })} `}
              {(primaryTitle || primaryTitleCustom) &&
                (primaryTitle ? (
                  <strong title={primaryTitle.length > 20 ? primaryTitle : ""}>
                    {primaryTitle}
                  </strong>
                ) : (
                  primaryTitleCustom
                ))}
              {secondaryText && (
                <span>
                  {` ${intl.formatMessage({ id: secondaryText })} `}
                  <strong
                    title={secondaryTitle.length > 20 ? secondaryTitle : ""}
                  >
                    {secondaryTitle}
                  </strong>
                </span>
              )}
              {link && link}
            </div>
          )}
          {activityContext === "project" &&
            environmentId && (
              <div className="environment">
                <Link
                  className="environmentLink"
                  to={`/${organization}/${project}/${encodeURIComponent(
                    environmentId
                  )}`}
                >
                  {environment}
                </Link>
              </div>
            )}
        </div>
      </ActivityInfo>
    );
  }
}

ActivityTitle.propTypes = {
  name: PropTypes.string,
  picture: PropTypes.string,
  primaryTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  primaryTitleCustom: PropTypes.node,
  secondaryTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  description: PropTypes.string,
  environment: PropTypes.string,
  environmentId: PropTypes.string,
  organization: PropTypes.string,
  project: PropTypes.string,
  intl: PropTypes.object,
  link: PropTypes.node,
  userId: PropTypes.string,
  activityContext: PropTypes.string,
  commits: PropTypes.array,
  onClick: PropTypes.func
};

export default injectIntl(ActivityTitle);
