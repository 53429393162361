import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withRouter, Link } from "react-router";
import Highlighter from "react-highlight-words";

import { goToEnvironment } from "Libs/utils";

const MenuLayout = styled.li`
  position: relative;
  .children {
    padding-left: 0;
    margin-left: 0;
  }
  &.with-children {
    .environment-link {
      padding-left: 1em;
    }
    &.is-root > .link-wrapper > .link {
      font-weight: 600;
    }
  }
  &.inactive > .link-wrapper > .link {
    color: #38485e;
  }
  &.active > .link-wrapper .link,
  .link-wrapper .link:active {
    color: ${props => props.theme.links};
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    margin: 0;
    position: relative;
    span.active {
      position: absolute;
      top: 14px;
      left: 4px;
      display: ${props =>
        props.theme.name === "contrast" ? "inline-block" : "none"};
      height: 4px;
      width: 4px;
      border-radius: 4px;
      background: ${props => props.theme.links};
    }
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;

  .link {
    color: #38485e;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    line-height: 32px;
    font-size: 14px;
    min-width: 150px;
    white-space: nowrap;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 16px;
    &:hover {
      background: ${props => props.theme.menuHover};
      border-radius: 4px;
      outline: none;
    }
    &:focus {
      outline: none;
      border: 1px solid #1664ff;
      border-radius: 4px;
      box-shadow: 0 0 2px #1664ff;
      margin: -1px;
    }
    .blue {
      color: ${props => props.theme.links};
      background: transparent;
    }
  }
  .search-result {
    > span {
      margin: 0;
    }
  }
`;

class MenuLinkItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
    this.toggleLink = this.toggleLink.bind(this);
    this.hasChildren = this.hasChildren.bind(this);
    this.returnClasses = this.returnClasses.bind(this);
  }

  toggleLink() {
    const expandedValue = !this.state.expanded;
    this.setState({
      expanded: expandedValue
    });
  }

  hasChildren(node) {
    if (node.children && node.children.length !== 0) {
      return true;
    }
    return false;
  }

  returnClasses(node, expanded, status) {
    let classValue = "environment-link";
    node.isRoot ? (classValue += " is-root") : "";
    node.environmentId == this.props.currentEnvironment
      ? (classValue += " active")
      : "";
    this.hasChildren(node) ? (classValue += " with-children") : "";
    expanded && this.hasChildren(node) ? (classValue += " expanded") : "";
    status == "inactive" ? (classValue += " inactive") : "";
    return classValue;
  }

  render() {
    const { node, id, organizationId, searchValue } = this.props;
    return (
      <MenuLayout
        key={node.title}
        className={this.returnClasses(node, this.state.expanded, node.status)}
      >
        <LinkWrapper className="link-wrapper">
          <Link
            id={`${id}-${node.environmentId}`}
            className="link"
            onClick={e => {
              e.preventDefault();
              goToEnvironment(
                this.props.router.push,
                this.props.organizationId,
                node.projectId,
                encodeURIComponent(node.environmentId)
              );
            }}
            tabIndex="0"
            href={`/${organizationId}/${node.projectId}/${encodeURIComponent(
              node.environmentId
            )}`}
            role="menuitem"
            aria-label={node.title}
          >
            {node.environmentId == this.props.currentEnvironment && (
              <span className="active" />
            )}
            <Highlighter
              highlightClassName="blue"
              searchWords={[searchValue]}
              textToHighlight={node.title ? node.title : ""}
              autoEscape={true}
              activeIndex={-1}
              caseSensitive={false}
            />
          </Link>
        </LinkWrapper>
        {node.children && (
          <ol>
            {node.children
              .sort((a, b) => {
                if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
                if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                return 0;
              })
              .filter(this.props.filter)
              .map(node => {
                return (
                  <MenuLinkItem
                    id={id}
                    node={node}
                    organizationId={this.props.organizationId}
                    currentEnvironment={this.props.currentEnvironment}
                    key={node.environmentId}
                    searchValue={this.props.searchValue}
                    filter={this.props.filter}
                    router={this.props.router}
                  />
                );
              })}
          </ol>
        )}
      </MenuLayout>
    );
  }
}

MenuLinkItem.propTypes = {
  isRoot: PropTypes.bool,
  node: PropTypes.object,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  organizationId: PropTypes.string,
  currentEnvironment: PropTypes.string,
  id: PropTypes.string,
  searchValue: PropTypes.string,
  filter: PropTypes.func
};

export default withRouter(MenuLinkItem);
