const util = require("../lib/util");

module.exports = addSVGLabel;

function addSVGLabel(root, node) {
  let domNode = root;

  domNode.node().appendChild(node.label);

  util.applyStyle(domNode, node.labelStyle);

  return domNode;
}
