import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ProjectOverview from "Containers/ProjectOverview";
import ErrorBoundary from "Components/ErrorBoundary";
import Redirect from "Components/Redirect";

const ProjectDetailLayout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

class ProjectDetail extends React.Component {
  constructor() {
    super();
    this.state = { redirect: false, redirectTo: "", redirected: false };
    this.setRedirect = this.setRedirect.bind(this);
  }

  setRedirect(redirectTo) {
    if (redirectTo && !this.state.redirected) {
      this.setState({ redirect: true, redirectTo });
    }
  }

  render() {
    if (
      this.state.redirect &&
      this.state.redirectTo &&
      !this.state.redirected
    ) {
      this.setState({ redirected: true });
      return <Redirect to={this.state.redirectTo} />;
    }
    return (
      <ProjectDetailLayout>
        <ErrorBoundary>
          <ProjectOverview
            setRedirect={this.setRedirect}
            redirected={this.state.redirected}
            {...this.props.params}
          />
        </ErrorBoundary>
      </ProjectDetailLayout>
    );
  }
}

ProjectDetail.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  params: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired,
    environmentId: PropTypes.string
  })
};

export default ProjectDetail;
