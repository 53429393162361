import React from "react";
import PropTypes from "prop-types";

const SearchIcon = ({ id, color = "#fff" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id={`${id}-mask0`}
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8 10.3L15.7 14.2C16 14.6 16 15.2 15.6 15.6C15.2 16 14.6 16 14.2 15.6L10.3 11.7C9.3 12.5 7.9 13 6.5 13C2.9 13 0 10.1 0 6.50005C0 2.90005 2.9 4.57764e-05 6.5 4.57764e-05C10.1 4.57764e-05 13 2.90005 13.1 6.50005C13.1 7.90005 12.6 9.20005 11.8 10.3ZM2 6.50005C2 9.00005 4 11 6.5 11C9 11 11 9.00005 11 6.50005C11 4.00005 9 2.00005 6.5 2.00005C4 2.00005 2 4.00005 2 6.50005Z"
        fill={color}
      />
    </mask>
    <g mask={`url(#${id}-mask0)`}>
      <rect x="-4" y="-3.99995" width="24" height="24" fill={color} />
    </g>
  </svg>
);

SearchIcon.propTypes = {
  color: PropTypes.string,
  id: PropTypes.string
};

export default SearchIcon;
