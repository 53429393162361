import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 78px;
  border-radius: 4px;
  background: transparent;
  height: 40px;
  box-sizing: border-box;
  padding: 1px;
  @media (min-width: 1280px) {
    margin-left: 32px;
  }
  span,
  a {
    padding: 4px;
    height: 32px;
    width: 32px;
    min-width: 32px;
    cursor: pointer;
    background-color: transparent;
    text-align: center;
    box-sizing: border-box;
    border-radius: 2px;
    margin: 1px;
    display: flex;
    align-items: center;
    svg {
      width: 100%;
      max-width: 100%;
    }
    &:hover {
      background-color: ${props =>
        props.theme.name === "contrast" ? props.theme.buttonHover : "#fff"};
      box-shadow: 0 1px 4px rgba(152, 160, 171, 0.6);
      svg {
        opacity: 1;
        rect,
        path {
          fill: ${props =>
            props.theme.name === "contrast"
              ? props.theme.buttonText
              : props.theme.links} !important;
        }
      }
    }
    &:focus {
      outline: none;
      border: 1px solid ${props => props.theme.buttonHover};
      box-shadow: 0 0 2px ${props => props.theme.buttonHover};
      padding: 3px;
    }
    &:active {
      box-shadow: 0 1px 4px rgba(152, 160, 171, 0.6);
      border: none !important;
      padding: 4px;
    }
  }
  .active {
    background-color: ${props =>
      props.theme.name === "contrast" ? props.theme.buttonHover : "#fff"};
    box-shadow: 0 1px 4px rgba(152, 160, 171, 0.6);
    svg {
      opacity: 1;
      rect,
      path {
        fill: ${props =>
          props.theme.name === "contrast"
            ? props.theme.buttonText
            : props.theme.links} !important;
      }
    }
  }
`;
