import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-size: 14px;
  overflow: hidden;
  box-sizing: border-box;
  padding-right: 64px;
  line-height: 24px;
  .icon {
    height: 24px;
    min-width: 24px;
    border-radius: 2px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    svg {
      width: auto;
    }
  }
  .icon-wrapper {
    display: flex;
    align-items: center;
  }
  .title {
    width: 100%;
    padding-left: 16px;
  }
  @media (min-width: 600px) {
    font-size: 16px;
    padding-right: 88px;
  }
  @media (min-width: 1280px) {
    padding-right: 218px;
  }
`;
