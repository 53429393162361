import React from "react";

const IconLayoutList2 = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 11H5C4.4 11 4 11.4 4 12C4 12.6 4.4 13 5 13H19C19.6 13 20 12.6 20 12C20 11.4 19.6 11 19 11Z"
      fill="#5f5e70"
    />
    <path
      d="M19 16H5C4.4 16 4 16.4 4 17C4 17.6 4.4 18 5 18H19C19.6 18 20 17.6 20 17C20 16.4 19.6 16 19 16Z"
      fill="#5f5e70"
    />
    <path
      d="M19 6H5C4.4 6 4 6.4 4 7C4 7.6 4.4 8 5 8H19C19.6 8 20 7.6 20 7C20 6.4 19.6 6 19 6Z"
      fill="#5f5e70"
    />
  </svg>
);

export default IconLayoutList2;
