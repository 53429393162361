const util = require("./util"),
  d3 = require("d3");
module.exports = createEdgePaths;

function createEdgePaths(selection, g, options) {
  const arcLength = options.arcLength,
    sameLayerOffset = options.sameLayerOffset,
    crossLayerOffset = options.crossLayerOffset;

  const sortBy = key => {
    return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
  };

  const sortedEdges = g
    .edges()
    .concat()
    .sort(
      sortBy(e => {
        return g.edge(e).active ? 1 : -1;
      })
    );

  let svgNodes = selection
    .selectAll("path.edgePath")
    .data(sortedEdges, function(e) {
      return util.edgeToId(e);
    })
    .classed("update", true)
    .raise();

  svgNodes
    .enter()
    .append("path")
    .attr("class", "edgePath");

  svgNodes.exit().remove();

  let generatePath = function(e) {
    let node = g.node(e.v);
    let edge = g.edge(e);
    let target = g.node(e.w);

    // console.log('POINT', e.w);

    let points = edge.points;

    // Revert the effects of assignNodeIntersects: use the center of source
    // and target node as start and end of the edge.
    points[0] = { x: node.x, y: node.y };
    points[points.length - 1] = { x: target.x, y: target.y };

    let path = new d3.path();
    let nodeRadius = node.width / 2 + 5;

    if (edge.class == "same") {
      // Connections in the same layer.
      if (Math.abs(node.order - target.order) == 1) {
        // Side by side nodes: connect directly.
        let directionX = points[1].x > points[0].x ? 1 : -1;

        path.moveTo(points[0].x + directionX * nodeRadius, points[0].y);
        path.lineTo(points[1].x - directionX * nodeRadius, points[1].y);
      } else {
        // Else, connect through the bottom.
        path.moveTo(points[0].x, points[0].y + nodeRadius);
        path.lineTo(points[0].x, points[0].y + sameLayerOffset - arcLength);
        path.arcTo(
          points[0].x,
          points[0].y + sameLayerOffset,
          points[0].x + arcLength,
          points[0].y + sameLayerOffset,
          arcLength
        );
        path.lineTo(points[1].x - arcLength, points[1].y + sameLayerOffset);
        path.arcTo(
          points[1].x,
          points[1].y + sameLayerOffset,
          points[1].x,
          points[1].y + sameLayerOffset - arcLength,
          arcLength
        );
        path.lineTo(points[1].x, points[1].y + nodeRadius);
      }
    } else {
      // Connections across layers.
      let startPoint = points[0];
      path.moveTo(startPoint.x, startPoint.y + nodeRadius);
      for (let i = 1; i < points.length; i++) {
        let point = points[i];

        if (point.x == startPoint.x) {
          path.lineTo(point.x, point.y - nodeRadius);
        } else {
          let rounded = i > 1 || !edge.straightCross;

          let directionX = point.x > startPoint.x ? 1 : -1;
          let midY = startPoint.y + crossLayerOffset;

          if (rounded) {
            path.lineTo(startPoint.x, midY - arcLength);
            path.arcTo(
              startPoint.x,
              midY,
              startPoint.x + directionX * arcLength,
              midY,
              arcLength
            );
          } else {
            path.lineTo(startPoint.x, midY);
          }

          path.lineTo(point.x - directionX * arcLength, midY);

          path.arcTo(point.x, midY, point.x, point.y - nodeRadius, arcLength);
          path.lineTo(point.x, point.y - nodeRadius);
          // console.log(edge.points[i]);
        }

        startPoint = point;
      }
    }

    return path.toString();
  };

  selection.selectAll("path.edgePath").each(function(e) {
    let edgePath = d3.select(this);
    let edge = g.edge(e);
    const classList = ["edgePath", edge.class];
    if (edge.active) {
      classList.push("active");
    }

    const classes = classList.join(" ");
    edgePath.attr("class", classes);
    edgePath.attr("d", generatePath);
  });

  return svgNodes;
}
